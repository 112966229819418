<template>
	<p>{{ message }}</p>
</template>

<script>
import http from "@/scripts/axios-config";
import { useUserStore } from "@/stores/userStore";
export default {
	name: "VerificaAccount",
	data() {
		return {
			message: "",
		};
	},
	async created() {
		let token = this.$route.params.token;
		try {
			let response = await http.post("/verifica-token-registrazione", {
				token,
			});
			let authToken = response.data.authToken;
			let user = useUserStore();
			user.authToken = authToken;
			http.defaults.headers.common["Authorization"] = authToken;
			user.nominativo = response.data.nominativo;
			user.negozi = response.data.negozi;
			this.$router.push({ name: "home" });
		} catch (error) {
			console.log(error);
			this.message = "Link invalido o scaduto";
		}
	},
};
</script>
