<template>
	<alert
		:show="showAlert"
		:message="alertMessage"
		:mode="alertMode"
		@close="showAlert = false"
	></alert>
	<div class="flex flex-col items-center text-black">
		<h1 class="text-2x font-medium my-3">
			{{ titolo }}
		</h1>
		<form @submit.prevent="inviaForm" class="flex flex-col px-2 max-w-full">
			<custom-select
				label="Negozio"
				name="negozio"
				:negozi="negozi"
				v-if="tipo == 'negozio'"
				ref="negozio"
			/>
			<custom-input
				label="Telefono"
				name="telefono"
				type="tel"
				ref="telefono"
				v-if="
					tipo == 'utente' &&
					(metodo == 'registrazione' || metodo == 'login')
				"
			/>
			<keep-alive>
				<custom-input
					v-if="metodo == 'registrazione' && tipo == 'utente'"
					label="Nominativo"
					name="nome"
					type="text"
					ref="nome"
				/>
			</keep-alive>
			<keep-alive>
				<custom-input
					v-if="
						(metodo == 'registrazione' ||
							metodo == 'richiedi-recupero') &&
						tipo == 'utente'
					"
					label="Email"
					name="email"
					type="text"
					ref="email"
				/>
			</keep-alive>
			<keep-alive>
				<custom-input
					v-if="!(tipo == 'utente' && metodo == 'richiedi-recupero')"
					:label="
						metodo == 'recupero' ? 'Nuova password' : 'Password'
					"
					name="password"
					type="password"
					ref="password"
				/>
			</keep-alive>
			<button
				class="
					bg-red-dark
					py-1
					mt-4
					rounded-md
					w-full
					text-white text-0x
					font-medium
					h-[43.2px]
				"
				type="submit"
				:disabled="disable"
			>
				Prosegui
			</button>
		</form>
		<div
			v-if="tipo == 'utente' && metodo != 'recupero'"
			@click="
				metodo = metodo == 'registrazione' ? 'login' : 'registrazione'
			"
			class="flex flex-col items-center mt-6 cursor-pointer"
		>
			<p>
				{{
					metodo == "registrazione"
						? "Hai già un account?"
						: "Non hai un account?"
				}}
			</p>
			<p class="text-red-dark">
				{{ metodo == "registrazione" ? "Log In" : "Registrati" }}
			</p>
		</div>
		<p
			v-if="tipo == 'utente' && metodo == 'login'"
			class="mt-5 cursor-pointer p-1"
			@click="metodo = 'richiedi-recupero'"
		>
			Password dimenticata?
		</p>
	</div>
</template>

<script>
import CustomInput from "@/components/CustomInput.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import Alert from "@/components/Alert.vue";
import http from "@/scripts/axios-config";
import { useCache } from "@/stores/cache";
import { useUserStore } from "@/stores/userStore";
import { useNegozioStore } from "@/stores/negozioStore";

export default {
	name: "AuthForm",
	components: {
		CustomInput,
		CustomSelect,
		Alert,
	},
	props: {
		tipo: {
			type: String,
			default: "utente", //utente, negozio
		},
		metodo: {
			type: String,
			default: "login", // registrazione, login, richiedi-recupero, recupero
		},
	},
	data() {
		return {
			emptyError: "Questo campo è obbligatorio",
			duplicatePhoneError:
				"Esiste già un account associato a questo telefono",
			duplicateEmailError:
				"Esiste già un account associato a questa email",
			emailNotFoundError:
				"Non esiste un account associato a questa email",
			disable: false,
			negozi: [],
			showAlert: false,
			messaggi: {
				email: "Abbiamo inviato una email di verifica nella tua casella di posta elettronica",
				recupero:
					"Abbiamo inviato una email nella tua casella di posta elettronica",
				credenziali: "Credenziali errate",
				errore: "Si è verificato un errore, riprova più tardi",
			},
			alertMode: "",
			alertMessage: "",
		};
	},
	computed: {
		titolo() {
			let string;
			switch (this.metodo) {
				case "login":
					string = "Log In";
					break;
				case "registrazione":
					string = "Registrazione";
					break;
				case "richiedi-recupero":
				case "recupero":
					string = "Recupero password";
					break;
				default:
					break;
			}
			return string;
		},
	},
	methods: {
		async inviaForm() {
			let obj = this.$refs;
			let refs;
			if (this.tipo == "negozio") {
				refs = {
					password: obj.password,
				};
			} else if (this.metodo == "registrazione") {
				refs = {
					telefono: obj.telefono,
					nome: obj.nome,
					email: obj.email,
					password: obj.password,
				};
			} else if (this.metodo == "login") {
				refs = {
					telefono: obj.telefono,
					password: obj.password,
				};
			} else if (this.metodo == "richiedi-recupero") {
				refs = {
					email: obj.email,
				};
			} else if (this.metodo == "recupero") {
				refs = {
					password: obj.password,
				};
			}
			for (let [key, ref] of Object.entries(refs)) {
				if (!ref.value) {
					ref.error = this.emptyError;
					ref.errorType = "empty";
					ref.valid = false;
				}
			}
			if (
				Object.values(refs)
					.map((ref) => ref.valid)
					.some((x) => !x)
			) {
				return;
			}
			this.disable = true;
			if (this.tipo == "negozio") {
				let data = {
					tipo: "negozio",
					id: obj.negozio.value,
					password: refs.password.value,
				};
				try {
					let response = await http.post("/login", data);
					let authToken = response.data.authToken;
					let negozio = useNegozioStore();
					negozio.authToken = authToken;
					http.defaults.headers.common["Authorization"] = authToken;
					negozio.nome = response.data.nome;
					this.$router.push({ name: "home-negozio" });
				} catch (error) {
					if (error.response.status == 400) {
						console.log("Credenziali errate");
					} else {
						console.log("Si è verificato un errore");
					}
				}
			} else if (this.metodo == "registrazione") {
				let data = {
					telefono: refs.telefono.value,
					nominativo: refs.nome.value,
					email: refs.email.value,
					password: refs.password.value,
				};
				try {
					let response = await http.post("/utenti", data);
					if (response.status == 201) {
						this.showAlert = true;
						this.alertMode = "success";
						this.alertMessage = this.messaggi.email;
					}
				} catch (error) {
					console.log(error);
					if (error.response.status == 400) {
						let messaggio = error.response.data.messaggio;
						if (messaggio == this.duplicatePhoneError) {
							this.$refs.telefono.error = messaggio;
							this.$refs.telefono.errorType = "format";
							this.$refs.telefono.valid = false;
						} else if (messaggio == this.duplicateEmailError) {
							this.$refs.email.error = messaggio;
							this.$refs.email.errorType = "format";
							this.$refs.email.valid = false;
						} else {
							this.showAlert = true;
							this.alertMode = "error";
							this.alertMessage = this.messaggi.errore;
						}
					}
				}
			} else if (this.metodo == "login") {
				let data = {
					tipo: "utente",
					telefono: refs.telefono.value,
					password: refs.password.value,
				};
				try {
					let response = await http.post("/login", data);
					let authToken = response.data.authToken;
					let user = useUserStore();
					user.authToken = authToken;
					http.defaults.headers.common["Authorization"] = authToken;
					user.nominativo = response.data.nominativo;
					user.negozi = response.data.negozi;
					this.$router.push({ name: "home" });
				} catch (error) {
					console.log(error);
					this.showAlert = true;
					this.alertMode = "error";
					if (error.response.status == 400) {
						this.alertMessage = this.messaggi.credenziali;
					} else {
						this.alertMessage = this.messaggi.errore;
					}
				}
			} else if (this.metodo == "richiedi-recupero") {
				let data = {
					email: refs.email.value,
				};
				try {
					let response = await http.post(
						"/richiedi-token-recupero",
						data
					);
					if (response.status == 201) {
						this.showAlert = true;
						this.alertMode = "success";
						this.alertMessage = this.messaggi.recupero;
					}
				} catch (error) {
					console.log(error);
					let messaggio = error.response.data.messaggio;
					if (
						error.response.status == 400 &&
						messaggio == this.emailNotFoundError
					) {
						this.$refs.email.error = messaggio;
						this.$refs.email.errorType = "format";
						this.$refs.email.valid = false;
					} else {
						this.showAlert = true;
						this.alertMode = "error";
						this.alertMessage = this.messaggi.errore;
					}
				}
			} else if (this.metodo == "recupero") {
				let token = this.$route.params.token;
				let data = {
					password: refs.password.value,
					token,
				};
				try {
					let response = await http.post(
						"/verifica-token-recupero",
						data
					);
					let authToken = response.data.authToken;
					let user = useUserStore();
					user.authToken = authToken;
					http.defaults.headers.common["Authorization"] = authToken;
					user.nominativo = response.data.nominativo;
					user.negozi = response.data.negozi;
					this.$router.push({ name: "home" });
				} catch (error) {
					console.log(error);
					this.showAlert = true;
					this.alertMode = "error";
					this.alertMessage = this.messaggi.errore;
				}
			}
			this.disable = false;
		},
	},
	async created() {
		let cache = useCache();
		this.negozi = await cache.fetch("/utenti/lista-negozi");
	},
};
</script>