import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            authToken: '',
            nominativo: '',
            negozi: [],
            ordini: [],
            socket: null,
        };
    },
    getters: {
        idOrdini: (state) => new Map(state.ordini.map(ordine => [ordine.id, ordine])),
    },
    persist: {
        paths: ['authToken', 'nominativo', 'negozi'],
    }
});