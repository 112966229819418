<template>
	<router-view />
</template>

<script>
import { useCache } from "@/stores/cache";
import { useUserStore } from "@/stores/userStore";
import http from "@/scripts/axios-config";
import { socketIoUrl, socketIoPath } from "@/scripts/utils.js";
import { io } from "socket.io-client";

export default {
	name: "Utente",
	async created() {
		let cache = useCache();
		let user = useUserStore();
		let negozi = user.negozi;
		let response;
		try {
			await cache.init([
				"/utenti/lista-negozi",
				...negozi.map((id) => `/utenti/menu/${id}`),
			]);
			response = await http.get("/utenti/ordini");
			user.ordini = response.data;
		} catch (error) {
			console.error(error);
		}
		user.socket = io(socketIoUrl, {
			path: socketIoPath,
			auth: {
				token: user.authToken,
			},
		});
		user.socket.on("nuovo stato", (idOrdine, stato) => {
			user.idOrdini.get(idOrdine).stato = stato;
		});

		user.socket.on("orario", (idNegozio, orari) => {
			console.log("orario", idNegozio, orari);
		});
	},
};
</script>