<template>
	<div class="text-black w-full h-full">
		<div class="font-medium shadow relative z-20 flex overflow-x-hidden">
			<div class="flex justify-between items-center w-full py-4 px-4">
				<div
					class="flex items-center cursor-pointer px-1"
					@click="previousStep"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="28"
						height="28"
						viewBox="0 0 39 39"
						class="drop-shadow-sm"
					>
						<g
							id="Group_14"
							data-name="Group 14"
							transform="translate(709.616 816) rotate(180)"
						>
							<circle
								id="Ellipse_2"
								data-name="Ellipse 2"
								cx="19.5"
								cy="19.5"
								r="19.5"
								transform="translate(670.616 777)"
								class="fill-shadow"
							/>
							<path
								id="Union_3"
								data-name="Union 3"
								d="M6.361,15.13a1.85,1.85,0,0,1,0-2.618L9.185,9.688H1.851a1.851,1.851,0,1,1,0-3.7H9.187L6.361,3.16A1.851,1.851,0,0,1,8.979.542l5.985,5.985a1.852,1.852,0,0,1,0,2.618L8.979,15.13a1.85,1.85,0,0,1-2.618,0Z"
								transform="translate(682.555 788.472)"
								class="fill-black"
							/>
						</g>
					</svg>
					<p class="ml-2">Indietro</p>
				</div>
				<div
					class="flex items-center justify-between px-1"
					:class="{
						'cursor-pointer': numeroPizze,
						'opacity-0': cartStore.negozioCorrente == null,
					}"
					@click="vaiRiepilogo"
				>
					<div ref="containerPizze" class="flex">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="30"
							viewBox="0 0 24 24"
							class="stroke-black stroke-[1.5] rotate-180"
							fill="none"
							stroke-linecap="round"
							stroke-linejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path
								d="M12 21.5c-3.04 0 -5.952 -.714 -8.5 -1.983l8.5 -16.517l8.5 16.517a19.09 19.09 0 0 1 -8.5 1.983z"
							/>
							<path
								d="M5.38 15.866a14.94 14.94 0 0 0 6.815 1.634a14.944 14.944 0 0 0 6.502 -1.479"
							/>
							<path d="M13 11.01v-.01" />
							<path d="M11 14v-.01" />
						</svg>
						<div class="relative">
							<p class="px-2">{{ numeroPizze }}</p>
						</div>
					</div>
					<div ref="containerBevande" class="flex">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="30"
							viewBox="0 0 24 24"
							class="stroke-black stroke-[1.5]"
							fill="none"
							stroke-linecap="round"
							stroke-linejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path
								d="M10 5h4v-2a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v2z"
							/>
							<path
								d="M14 3.5c0 1.626 .507 3.212 1.45 4.537l.05 .07a8.093 8.093 0 0 1 1.5 4.694v6.199a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2v-6.2c0 -1.682 .524 -3.322 1.5 -4.693l.05 -.07a7.823 7.823 0 0 0 1.45 -4.537"
							/>
							<path
								d="M7.003 14.803a2.4 2.4 0 0 0 .997 -.803a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 1 -.805"
							/>
						</svg>
						<div>
							<p class="px-1">{{ numeroBevande }}</p>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					absolute
					w-full
					h-full
					top-0
					left-0
					bg-black
					text-white
					flex
					justify-between
					items-center
					py-4
					px-5
					cursor-pointer
				"
				style="transform: translateX(100%)"
				ref="ripristina"
				@click="ripristinaCarrello()"
			>
				<div class="flex items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="30"
						height="30"
						viewBox="0 0 24 24"
						class="stroke-white stroke-[1.5]"
						fill="none"
						stroke-linecap="round"
						stroke-linejoin="round"
					>
						<path stroke="none" d="M0 0h24v24H0z" fill="none" />
						<path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
					</svg>
					<p class="ml-2">Annulla</p>
				</div>
				<div class="relative">
					<svg
						ref="circle"
						xmlns="http://www.w3.org/2000/svg"
						width="36"
						height="36"
						viewBox="0 0 24 24"
						class="stroke-white stroke-[1.5] -rotate-90"
						fill="none"
						stroke-linecap="round"
						stroke-linejoin="round"
						:style="{
							'stroke-dasharray': pathLength + ' ' + pathLength,
							'stroke-dashoffset': 0,
						}"
					>
						<circle cx="12" cy="12" r="9" />
					</svg>
					<p
						class="
							absolute
							top-1/2
							left-1/2
							-translate-x-1/2 -translate-y-1/2
						"
					>
						{{ counter }}
					</p>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import { useCartStore } from "@/stores/cartStore";
import anime from "animejs/lib/anime.es.js";

export default {
	name: "Ordina",
	data() {
		return {
			cartStore: useCartStore(),
			timeOut: 5000,
			timeOutId: null,
			timeIntervalId: null,
			pathLength: 0,
			counter: 5,
		};
	},
	computed: {
		numeroPizze() {
			return this.cartStore.carrelloPizze.length;
		},
		numeroBevande() {
			return this.cartStore.carrelloBevande.length;
		},
	},
	methods: {
		previousStep() {
			let name;
			switch (this.$route.name) {
				case "scegliNegozio":
					name = "home";
					break;
				case "scegliOrdine":
					name = "scegliNegozio";
					break;
				case "riepilogo":
					name = "scegliOrdine";
					break;
				case "scegliOrario":
					name = "riepilogo";
					break;
				default:
					name = "home";
					break;
			}
			this.$router.push({ name });
		},
		vaiRiepilogo() {
			if (!this.numeroPizze) return;
			this.$router.push({ name: "riepilogo" });
		},
		async mostraRipristina() {
			this.counter = 5;
			let ref = this;
			if (!this.timeOutId) {
				let animation = anime({
					targets: this.$refs.ripristina,
					translateX: 0,
					duration: 400,
					easing: "easeOutCubic",
				});
				await animation.finished;
			}
			anime({
				targets: this.$refs.circle,
				strokeDashoffset: [0, this.pathLength],
				duration: ref.timeOut,
				easing: "linear",
			});
			if (this.timeIntervalId) clearInterval(this.timeIntervalId);
			this.timeIntervalId = setInterval(() => ref.counter--, 1000);
			if (this.timeOutId) clearTimeout(this.timeOutId);
			this.timeOutId = setTimeout(this.nascondiRipristina, this.timeOut);
		},
		async nascondiRipristina() {
			let animation = anime({
				targets: this.$refs.ripristina,
				translateX: "100%",
				duration: 400,
				easing: "easeInCubic",
			});
			clearInterval(this.timeIntervalId);
			this.cartStore.scartaBackup();
			this.timeOutId = null;
			await animation.finished;
			anime.remove(this.$refs.circle);
			anime.set(this.$refs.circle, {
				strokeDashoffset: 0,
			});
		},
		ripristinaCarrello() {
			this.cartStore.ripristinaCarrello();
			clearTimeout(this.timeOutId);
			this.nascondiRipristina();
		},
		bounce(el) {
			anime({
				targets: el,
				keyframes: [
					{ translateY: 8 },
					{ translateY: -6 },
					{ translateY: 4 },
					{ translateY: 0 },
				],
				duration: 600,
				easing: "spring(1, 80, 10, 0)",
			});
		},
	},
	watch: {
		numeroPizze(newValue, oldValue) {
			if (newValue < oldValue && this.$route.name == "riepilogo") {
				this.mostraRipristina();
			} else if (newValue > oldValue) {
				this.bounce(this.$refs.containerPizze);
			}
		},
		numeroBevande(newValue, oldValue) {
			if (newValue < oldValue && this.$route.name == "riepilogo") {
				this.mostraRipristina();
			} else if (newValue > oldValue) {
				this.bounce(this.$refs.containerBevande);
			}
		},
	},
	mounted() {
		this.pathLength = this.$refs.circle
			.querySelector("circle")
			.getTotalLength();
		this.numeroPizzeTemp = this.numeroPizze;
		this.numeroBevandeTemp = this.numeroBevande;
	},
};
</script>
