import { createRouter, createWebHistory } from "vue-router";
import AuthForm from "@/views/AuthForm";
import Home from "@/views/Home";
import Ordina from "@/views/Ordina";
import Negozio from "@/views/Negozio";
import ScegliNegozio from "@/components/ScegliNegozio";
import ScegliOrdine from "@/components/ScegliOrdine.vue";
import Riepilogo from "@/components/Riepilogo.vue";
import ScegliOrario from "@/components/ScegliOrario.vue";
import Utente from "@/views/Utente";
import VerificaAccount from "@/views/VerificaAccount";
import { isLoggedIn, getJwtAudience } from "@/scripts/utils";
import { useCartStore } from "@/stores/cartStore";
import { useUserStore } from "@/stores/userStore";

const routes = [
  {
    path: "/login/negozio",
    name: "authform-negozio",
    component: AuthForm,
    props: {
      tipo: "negozio",
    },
  },
  {
    path: "/negozio/home",
    name: "home-negozio",
    component: Negozio,
    meta: {
      requiresAuth: true,
      audience: "negozio",
    },
  },
  {
    path: "/login/utente",
    name: "authform",
    alias: "/",
    component: AuthForm,
    props: { tipo: "utente" },
  },
  {
    path: "/verifica/:token",
    name: "verificaAccount",
    component: VerificaAccount,
  },
  {
    path: "/recupero/:token",
    name: "recuperoAccount",
    component: AuthForm,
    props: {
      tipo: "utente",
      metodo: "recupero",
    },
  },
  {
    path: "/utente",
    component: Utente,
    meta: {
      requiresAuth: true,
      audience: "utente",
    },
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "ordina",
        name: "ordina",
        component: Ordina,
        children: [
          {
            path: "",
            name: "scegliNegozio",
            component: ScegliNegozio,
            alias: "scegli-negozio",
          },
          {
            path: "scegli-ordine",
            name: "scegliOrdine",
            component: ScegliOrdine,
            meta: {
              requiresStore: true,
            },
          },
          {
            path: "riepilogo",
            name: "riepilogo",
            component: Riepilogo,
            meta: {
              requiresStore: true,
            },
          },
          {
            path: "scegli-orario",
            name: "scegliOrario",
            component: ScegliOrario,
            meta: {
              requiresStore: true,
            },
          },
          {
            path: ":catchAll(.*)",
            redirect: {
              name: "scegliNegozio",
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let cartStore = useCartStore();
  let user = useUserStore();
  if (to.meta.requiresAuth && isLoggedIn(to.meta.audience) != "loggedIn") {
    if (to.meta.audience == "negozio") {
      next({ name: "authform-negozio" });
    } else {
      next({ name: "authform" });
    }
  } else if (
    (to.name == "authform" || to.name == "authform-negozio") &&
    isLoggedIn() == "loggedIn"
  ) {
    if (getJwtAudience() == "utente") {
      next({ name: "home" });
    } else if (getJwtAudience() == "negozio") {
      next({ name: "home-negozio" });
    }
  } else if (to.meta.requiresStore && !cartStore.negozioCorrente) {
    next({ name: "scegliNegozio" });
  } else if (!from.meta.requiresStore && to.meta.requiresStore) {
    console.log("join orario", cartStore.negozioCorrente);
    user.socket.emit("join orario", cartStore.negozioCorrente);
    next();
  } else if (from.meta.requiresStore && !to.meta.requiresStore) {
    console.log("leave orario", cartStore.negozioCorrente);
    user.socket.emit("leave orario", cartStore.negozioCorrente);
    next();
  } else {
    next();
  }
});

export default router;
