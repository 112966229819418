<template>
	<div class="order-page overflow-y-scroll overflow-x-hidden bg-white" ref="sezioneScegliOrdine" v-if="flagPizze">
		<h1 class="text-2x font-medium px-5">Menù</h1>
		<div class="flex font-medium mt-3 mb-8 px-5">
			<button class="rounded-md shadow-md p-2 my-3 mr-6" :class="{ 'text-red': cartStore.sezioneAttiva == 'pizze' }"
				@click="cartStore.sezioneAttiva = 'pizze'">
				Pizze
			</button>
			<button class="rounded-md shadow-md p-2 my-3" :class="{ 'text-red': cartStore.sezioneAttiva == 'bevande' }"
				@click="cartStore.sezioneAttiva = 'bevande'">
				Bevande
			</button>
		</div>
		<div v-show="cartStore.sezioneAttiva == 'pizze'">
			<div class="my-4 mx-3 shadow-md px-3 py-3 flex rounded-2xl">
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"
					class="stroke-black stroke-[1.5] mx-1" fill="none" stroke-linecap="round" stroke-linejoin="round">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<circle cx="10" cy="10" r="7" />
					<line x1="21" y1="21" x2="15" y2="15" />
				</svg>
				<input class="w-full mx-3" type="text" v-model="inputRicerca" />
			</div>
			<!--
					:breakpoints="{
						'460': {
							slidesPerView: 2,
						},
						'680': {
							slidesPerView: 3,
						},
						'900': {
							slidesPerView: 4,
						},
					}"
			-->
			<transition-group name="slider" tag="div" class="relative">
				<div v-if="slidesPreferiti.length" key="preferiti" ref="sezionePreferiti">
					<h2 class="px-5 font-medium pt-3 pb-1">Preferite</h2>
					<swiper :slidesPerView="'auto'" :space-between="20" class="py-5 px-1 swiper-pizze">
						<swiper-slide v-for="pizza of slidesPreferiti" :key="pizza.id">
							<card-pizza :pizza="pizza" @mostraFinestraAggiunte="(pizza, card) =>
								mostraFinestraAggiunte(pizza, card)
								" />
						</swiper-slide>
					</swiper>
				</div>
				<div v-for="riga of slidesPizze" :key="riga.categoria">
					<h2 class="px-5 font-medium pt-3 pb-1">
						{{ capitalize(riga.categoria) }}
					</h2>
					<swiper :slidesPerView="'auto'" :space-between="20" class="py-5 px-1 swiper-pizze relative">
						<swiper-slide v-for="pizza of riga.lista" :key="pizza.id">
							<card-pizza :pizza="pizza" @mostraFinestraAggiunte="(pizza, card) =>
								mostraFinestraAggiunte(pizza, card)
								" />
						</swiper-slide>
					</swiper>
				</div>
			</transition-group>
		</div>
		<div v-show="cartStore.sezioneAttiva == 'bevande'">
			<div v-for="riga of slidesBevande" :key="riga.categoria">
				<h2 class="px-5 font-medium pt-3 pb-1">
					{{ capitalize(riga.categoria) }}
				</h2>
				<swiper :slidesPerView="'auto'" :space-between="20" class="py-5 px-1 swiper-bevande">
					<swiper-slide v-for="bevanda of riga.lista" :key="bevanda.id">
						<card-bevanda :bevanda="bevanda" />
					</swiper-slide>
				</swiper>
			</div>
		</div>
	</div>
	<finestra-aggiunte :mostraAggiunte="mostraAggiunte" :pizzaDaModificare="pizzaDaModificare" :cardOrigine="cardOrigine"
		@chiudiFinestraAggiunte="mostraAggiunte = false" />
</template>

<script>
import { capitalize } from "@/scripts/utils";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import Fuse from "fuse.js";
import CardPizza from "@/components/CardPizza.vue";
import CardBevanda from "@/components/CardBevanda.vue";
import FinestraAggiunte from "@/components/FinestraAggiunte.vue";
import { useCache } from "@/stores/cache";
import { useCartStore } from "@/stores/cartStore";
import { usePreferitiStore } from "@/stores/preferitiStore";

export default {
	name: "ScegliOrdine",
	components: {
		Swiper,
		SwiperSlide,
		CardPizza,
		CardBevanda,
		FinestraAggiunte,
	},
	data() {
		return {
			preferiti: usePreferitiStore(),
			cartStore: useCartStore(),
			slidesPizzeDefault: [],
			slidesBevande: [],
			pizze: {},
			mostraAggiunte: false,
			pizzaDaModificare: {
				nome: "null",
				ingredienti: [],
			},
			cardOrigine: null,
			inputRicerca: "",
			timerId: null,
			fuse: null,
			results: [],
			flagPizze: false,
			flagBevande: false,
			flagAggiunte: false,
		};
	},
	watch: {
		inputRicerca(newValue, oldValue) {
			if (this.timerId) clearTimeout(this.timerId);
			this.timerId = setTimeout(this.ricerca, 500);
		},
		/*
		async slidesPreferiti(newValue, oldValue) {
			if (!newValue.length && oldValue.length && !this.results.length) {
				let pagina = this.$refs.sezioneScegliOrdine;
				let oldScroll = pagina.scrollTop;
				//let { height: oldHeight } = pagina.getBoundingClientRect();
				let preferiti = this.$refs.sezionePreferiti;
				let { height: preferitiHeight } =
					preferiti.getBoundingClientRect();
				await this.$nextTick();
				let newScroll = pagina.scrollTop;
				console.log(oldScroll, newScroll);
				if (oldScroll == newScroll)
					pagina.scrollTop = newScroll - preferitiHeight;
			}
		},
		*/
	},
	computed: {
		slidesPizze() {
			if (!this.results.length) {
				return this.slidesPizzeDefault;
			}
			let lista = [];
			let index = 0;
			for (let { item } of this.results) {
				index = lista.findIndex((el) => el.categoria == item.categoria);
				if (index == -1) {
					lista.push({
						categoria: item.categoria,
						lista: [],
					});
					index = lista.length - 1;
				}
				lista[index].lista.push(item);
			}
			return lista;
		},
		slidesPreferiti() {
			if (!this.results.length) {
				let store = this.preferiti.store;
				let idNegozio = this.cartStore.negozioCorrente;
				let lista = store[idNegozio] ? [...store[idNegozio]] : [];
				if (Object.keys(this.pizze).length == 0) return [];
				return lista.map((id) => this.pizze[id]);
			}
			return this.results
				.map((el) => el.item)
				.filter((pizza) =>
					this.preferiti.has(this.cartStore.negozioCorrente, pizza)
				);
		},
	},
	methods: {
		capitalize,
		ricerca() {
			this.results = this.fuse.search(this.inputRicerca);
		},
		mostraFinestraAggiunte(pizza, card) {
			this.mostraAggiunte = true;
			this.pizzaDaModificare = pizza;
			this.ingredientiScelti = [...pizza.ingredienti];
			this.cardOrigine = card;
		},
	},
	updated() {
		console.log(this.cartStore.sezioneAttiva)
	},
	async created() {
		let data;
		try {
			let cache = useCache();
			data = await cache.fetch(
				`/utenti/menu/${this.cartStore.negozioCorrente}`
			);
		} catch (error) {
			console.log(error);
		}
		let index = 0;
		this.fuse = new Fuse(data.pizze, {
			keys: [
				{
					name: "nome",
					weight: 0.7,
				},
				{
					name: "ingredienti",
					weight: 0.3,
				},
			],
			threshold: 0.4,
		});
		for (let pizza of data.pizze) {
			this.pizze[pizza.id] = pizza;
			index = this.slidesPizzeDefault.findIndex(
				(el) => el.categoria == pizza.categoria
			);
			if (index == -1) {
				this.slidesPizzeDefault.push({
					categoria: pizza.categoria,
					lista: [],
				});
				index = this.slidesPizzeDefault.length - 1;
			}
			this.slidesPizzeDefault[index].lista.push(pizza);
		}
		index = 0;
		for (let bevanda of data.bevande) {
			index = this.slidesBevande.findIndex(
				(el) => el.categoria == bevanda.categoria
			);
			if (index == -1) {
				this.slidesBevande.push({
					categoria: bevanda.categoria,
					lista: [],
				});
				index = this.slidesBevande.length - 1;
			}
			this.slidesBevande[index].lista.push(bevanda);
		}
		this.flagPizze = data.pizze.length > 0;
		this.flagBevande = data.bevande.length > 0;
		this.flagAggiunte = data.aggiunte.length > 0;
	},
};
</script>

<style scoped>
.slider-move,
.slider-enter-active,
.slider-leave-active {
	transition: all 0.5s ease;
}

.slider-enter-from,
.slider-leave-to {
	opacity: 0 !important;
	transform: translateX(30px) !important;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.slider-leave-active {
	position: absolute;
}
</style>