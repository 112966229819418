import { defineStore } from 'pinia';

export const useCartStore = defineStore('cartStore', {
    state: () => {
        return {
            carrelli: {}, //lista dei carrelli
            negozioCorrente: null,
            carrelliBackup: {}, //lista carrelli che possono essere ripristinati entro 5 secondi
            keyGenerator: 1,
            sezioneAttiva: 'pizze' //pizze, bevande
        }
    },
    getters: {
        carrelloPizze(state) {
            return state.carrelli[this.negozioCorrente]?.contenuto?.pizze || [];
        },
        carrelloBevande(state) {
            return state.carrelli[this.negozioCorrente]?.contenuto?.bevande || [];
        },
        keySet(state) {
            let list = [];
            for (let idNegozio in state.carrelli) {
                list = list.concat(state.carrelli[idNegozio].contenuto.pizze.filter(x => x.key).map(x => x.key));
                list = list.concat(state.carrelli[idNegozio].contenuto.bevande.filter(x => x.key).map(x => x.key));
                list = list.concat(state.carrelliBackup[idNegozio].contenuto.pizze.filter(x => x.key).map(x => x.key));
                list = list.concat(state.carrelliBackup[idNegozio].contenuto.bevande.filter(x => x.key).map(x => x.key));
            }
            return new Set(list);
        }
    },
    actions: {
        //elimina definitivamente
        eliminaCarrello(idNegozio) {
            delete this.carrelli[idNegozio];
            delete this.carrelliBackup[idNegozio];
        },
        contieneCarrello(idNegozio) {
            return !!this.carrelloPizze.length || !!this.carrelloBevande.length;
        },
        //crea carrello e backup inizialmente vuoti
        creaCarrello(idNegozio) {
            if (!this.carrelli[idNegozio]) {
                this.carrelli[idNegozio] = {};
                let carrello = this.carrelli[idNegozio];
                carrello.idNegozio = idNegozio;
                carrello.contenuto = {};
                carrello.contenuto.pizze = [];
                carrello.contenuto.bevande = [];

                this.carrelliBackup[idNegozio] = {};
                let carrelloBackup = this.carrelliBackup[idNegozio];
                carrelloBackup.contenuto = {};
                carrelloBackup.contenuto.pizze = [];
                carrelloBackup.contenuto.bevande = [];
                carrelloBackup.flagMantieni = false;
            }
        },
        aggiungiPizza(pizza, tipo = 'normale', impasto = 'normale', doppiapasta = false, senza = [], aggiunte = []) {
            let item = {
                tipo,
                impasto,
                doppiapasta,
            };
            item.id = pizza.id;
            item.key = this.generateKey();
            if (senza.length) item.senza = senza;
            if (aggiunte.length) item.aggiunte = aggiunte;
            if (!this.carrelli[this.negozioCorrente]) this.creaCarrello(this.negozioCorrente);
            this.carrelli[this.negozioCorrente].contenuto.pizze.push(item);
        },
        aggiungiBevanda(bevanda) {
            let item = {};
            item.id = bevanda.id;
            item.key = this.generateKey();
            if (!this.carrelli[this.negozioCorrente]) this.creaCarrello(this.negozioCorrente);
            this.carrelli[this.negozioCorrente].contenuto.bevande.push(item);
        },
        salvaBackup() {
            let backup = this.carrelliBackup[this.negozioCorrente];
            backup.contenuto.pizze = [...this.carrelloPizze];
            backup.contenuto.bevande = [...this.carrelloBevande];
            backup.flagMantieni = true;
        },
        scartaBackup() {
            this.carrelliBackup[this.negozioCorrente].flagMantieni = false;
        },
        togliPizza(index) {
            let backup = this.carrelliBackup[this.negozioCorrente];
            if (!backup.flagMantieni) this.salvaBackup();
            this.carrelli[this.negozioCorrente].contenuto.pizze.splice(index, 1);
        },
        togliBevanda(index) {
            let backup = this.carrelliBackup[this.negozioCorrente];
            if (!backup.flagMantieni) this.salvaBackup();
            this.carrelli[this.negozioCorrente].contenuto.bevande.splice(index, 1);
        },
        ripristinaCarrello() {
            let backup = this.carrelliBackup[this.negozioCorrente].contenuto;
            this.carrelli[this.negozioCorrente].contenuto.pizze = [...backup.pizze];
            this.carrelli[this.negozioCorrente].contenuto.bevande = [...backup.bevande];
            backup.flagMantieni = false;
        },
        generateKey() {
            let key = this.keyGenerator;
            while (this.keySet.has(key) || key == 0) {
                key++;
                this.keyGenerator++;
            }
            this.keyGenerator++;
            return key;
        },
    },
    persist: {
        paths: ['carrelli'],
        afterRestore: context => {
            for (let idNegozio in context.store.carrelli) {
                context.store.carrelliBackup[idNegozio] = {};
                let carrelloBackup = context.store.carrelliBackup[idNegozio];
                carrelloBackup.contenuto = {};
                carrelloBackup.contenuto.pizze = [];
                carrelloBackup.contenuto.bevande = [];
                for (let pizza of context.store.carrelli[idNegozio].contenuto.pizze) {
                    if (!pizza.key) pizza.key = context.store.generateKey();
                }
                for (let bevanda of context.store.carrelli[idNegozio].contenuto.bevande) {
                    if (!bevanda.key) bevanda.key = context.store.generateKey();
                }
            }
        }
    }
});