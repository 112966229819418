<template>
  <div
    class="border border-shadow p-4 rounded-lg shadow-lg cursor-pointer bg-white"
    @click="espandi = !espandi"
  >
    <div class="flex font-medium justify-between flex-wrap items-center">
      <div class="flex pr-4 pt-2">
        <p>{{ capitalize(ordine.nominativo) }}</p>
        <p class="pl-4">
          prenotato per le
          {{ formatTime(ordine.prenotatoPerLe) }}
        </p>
      </div>
      <p class="pt-1">Totale: € {{ ordine.totale }}</p>
    </div>
    <p>Tel: {{ ordine.telefono }}</p>
    <p>Stato: {{ ordine.stato }}</p>
    <p v-if="ordine.nota">Nota: {{ ordine.nota }}</p>
    <p v-show="!espandi">Pizze: {{ ordine.contenuto.pizze.length }}</p>
    <p v-if="ordine.contenuto?.bevande?.length" v-show="!espandi">
      Bevande: {{ ordine.contenuto.bevande.length }}
    </p>
    <div v-show="espandi">
      <p class="font-medium">Pizze</p>
      <div v-for="(pizza, index) of ordine.contenuto.pizze" :key="index">
        <div class="flex justify-between">
          <p>{{ capitalize(pizza.nome) }}</p>
          <p>€ {{ pizza.prezzo }}</p>
        </div>
        <div class="flex flex-wrap">
          <p v-if="pizza.tipo != 'normale'" class="pr-1">
            {{ pizza.tipo }}
          </p>
          <p v-if="pizza.impasto != 'normale'" class="pr-1">
            {{ pizza.impasto }}
          </p>
          <p v-if="pizza.doppiapasta" class="pr-1">doppiapasta</p>
        </div>
        <p v-if="pizza?.senza?.length" class="px-3 py-2">
          Senza {{ pizza.senza.join(", ") }}
        </p>
        <p v-if="pizza?.aggiunte?.length" class="px-3 py-2">
          Con aggiunta di
          {{ pizza.aggiunte.map((aggiunta) => aggiunta.nome).join(", ") }}
        </p>
      </div>
      <p v-if="ordine.contenuto.bevande" class="font-medium">Bevande</p>
      <div
        v-for="(bevanda, index) of ordine.contenuto.bevande"
        :key="index"
        class="flex justify-between"
      >
        <p>{{ capitalize(bevanda.nome) }} {{ bevanda.litri }}l</p>
        <p>€ {{ bevanda.prezzo }}</p>
      </div>
    </div>
  </div>
  <div class="flex justify-center pt-6 pb-12">
    <button
      v-if="ordine.stato == 'non confermato'"
      class="p-2 border border-shadow shadow-md rounded-md bg-white mx-3"
      @click="$emit('conferma-e-stampa')"
    >
      Conferma e Stampa
    </button>
    <button
      v-if="ordine.stato == 'rifiutato'"
      class="p-2 border border-shadow shadow-md rounded-md bg-white mx-3"
      @click="$emit('conferma')"
    >
      Conferma
    </button>
    <button
      v-if="ordine.stato != 'rifiutato'"
      class="p-2 border border-shadow shadow-md rounded-md bg-white mx-3"
      @click="$emit('rifiuta')"
    >
      Rifiuta
    </button>
    <button
      v-if="ordine.stato != 'non confermato'"
      class="p-2 border border-shadow shadow-md rounded-md bg-white mx-3"
      @click="$emit('stampa')"
    >
      Stampa
    </button>
  </div>
</template>

<script>
import { capitalize, formatTime } from "@/scripts/utils.js";

export default {
  name: "CardOrdineNegozio",
  props: {
    ordine: Object,
  },
  emits: ["stampa", "conferma", "rifiuta", "conferma-e-stampa"],
  data() {
    return {
      espandi: false,
    };
  },
  methods: {
    capitalize,
    formatTime,
  },
};
</script>
