<template>
	<div class="
			rounded-xl
			shadow-xl
			grid grid-rows-10 grid-cols-3
			p-2
			w-full
			h-[336px]
			border border-shadow
			cursor-pointer
		" @click="onClick" ref="card">
		<svg ref="iconaPreferita" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
			class="stroke-[1.5] row-span-1" :class="{
				'stroke-red fill-red': preferita,
				'stroke-black': !preferita,
			}" fill="none" stroke-linecap="round" stroke-linejoin="round" @click.stop="
	preferiti.modificaPreferiti(cartStore.negozioCorrente, pizza)
	">
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
		</svg>
		<p class="row-span-1 col-span-2 justify-self-end"
			v-show="cartStore.carrelloPizze.find((item) => pizza.id == item.id)">
			x{{
				cartStore.carrelloPizze.filter((item) => pizza.id == item.id)
					.length
			}}
		</p>

		<CustomImg :url="pizza.foto || '/images/pizza-test'" class="col-span-3
				row-span-5
				overflow-hidden
				p-2
				max-w-full max-h-full
				flex
				justify-center
				items-center" />
		<p class="font-medium col-span-2 row-span-2 p-1">
			{{ capitalize(pizza.nome) }}
		</p>
		<p class="col-span-1 row-span-2 p-1">€ {{ pizza.prezzo }}</p>
		<p class="text-1s col-span-3 row-span-2 place-self-center">
			{{ pizza.descrizione }}
		</p>
	</div>
</template>

<script>
import { useCartStore } from "@/stores/cartStore";
import { usePreferitiStore } from "@/stores/preferitiStore";
import { capitalize } from "../scripts/utils";
import anime from "animejs/lib/anime.es.js";
import CustomImg from "./CustomImg.vue";

export default {
	name: "CardPizza",
	components: {
		CustomImg,
	},
	emits: ["mostra-finestra-aggiunte"],
	props: {
		pizza: Object,
	},
	data() {
		return {
			cartStore: useCartStore(),
			preferiti: usePreferitiStore(),
			delay: 300,
			clicks: 0,
			timer: null,
		};
	},
	computed: {
		preferita() {
			return this.preferiti.has(
				this.cartStore.negozioCorrente,
				this.pizza
			);
		},
	},
	watch: {
		preferita(newValue, oldValue) {
			if (newValue && !oldValue) {
				anime({
					targets: this.$refs.iconaPreferita,
					keyframes: [{ scale: 1.2 }, { scale: 0.8 }, { scale: 1 }],
					duration: 500,
					easing: "easeInOutCubic",
				});
			}
		},
	},
	methods: {
		capitalize,
		onClick(event, pizza) {
			this.clicks++;
			if (this.clicks === 1) {
				let context = this;
				this.timer = setTimeout(() => {
					context.clicks = 0;
					context.$emit(
						"mostra-finestra-aggiunte",
						context.pizza,
						context.$refs.card
					);
				}, this.delay);
			} else {
				clearTimeout(this.timer);
				this.clicks = 0;
				this.preferiti.modificaPreferiti(
					this.cartStore.negozioCorrente,
					this.pizza
				);
			}
		},
	},
};
</script>