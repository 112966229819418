<template>
	<div class="
			p-4
			my-3
			rounded-lg
			shadow-lg
			w-3/4
			sm:w-[400px]
			flex flex-col
			sm:flex-row
			items-center
			mx-auto
			sm:mx-0
		">
		<picture class="
				w-1/2
				sm:w-1/4
				max-h-[110px]
				flex
				justify-center
				items-center
			">
			<CustomImg :url="pizza.info.foto || '/images/pizza-test'" class="max-h-[110px] object-contain" />
		</picture>
		<div class="w-full sm:w-3/4 flex flex-col sm:pl-3">
			<div class="flex justify-between flex-wrap py-2">
				<p class="font-medium px-3 py-1">
					{{ capitalize(pizza.info.nome) }}
				</p>
				<p class="font-medium px-3 py-1">€ {{ pizza.prezzoTotale }}</p>
			</div>
			<div class="flex px-3 flex-wrap">
				<p v-if="pizza.tipo != 'normale'" class="pr-1">
					{{ pizza.tipo }}
				</p>
				<p v-if="pizza.impasto != 'normale'" class="pr-1">
					{{ pizza.impasto }}
				</p>
				<p v-if="pizza.doppiapasta" class="pr-1">doppiapasta</p>
			</div>
			<p v-if="pizza?.senza?.length" class="px-3 py-2">
				Senza {{ pizza.senza.join(", ") }}
			</p>
			<p v-if="pizza?.aggiunte?.length" class="px-3 py-2">
				Con aggiunta di
				{{
					pizza.aggiunte
						.map((aggiunta) => aggiunta.info.nome)
						.join(", ")
				}}
			</p>
		</div>
		<button class="p-1 mx-5" @click="cartStore.togliPizza(index)">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="stroke-black stroke-2"
				fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<line x1="18" y1="6" x2="6" y2="18" />
				<line x1="6" y1="6" x2="18" y2="18" />
			</svg>
		</button>
	</div>
</template>

<script>
import { useCartStore } from "@/stores/cartStore";
import { capitalize } from "@/scripts/utils";
import CustomImg from "./CustomImg.vue";

export default {
	name: "CardPizzaRiepilogo",
	components: {
		CustomImg,
	},
	props: {
		pizza: Object,
		index: Number,
	},
	data() {
		return {
			cartStore: useCartStore(),
		};
	},
	methods: {
		capitalize,
	},
};
</script>