<template>
	<div
		class="
			p-4
			my-3
			rounded-lg
			shadow-lg
			w-3/4
			sm:w-[400px]
			flex flex-col
			sm:flex-row
			items-center
			mx-auto
			sm:mx-0
		"
	>
		<picture
			class="
				w-1/2
				sm:w-1/4
				max-h-[110px]
				flex
				justify-center
				items-center
			"
		>
			<img
				:src="bevanda.info.foto || '/images/bevande/drink-default.png'"
				class="max-h-[110px] object-contain"
			/>
		</picture>
		<div class="w-full sm:w-3/4 flex flex-col sm:pl-3">
			<div class="flex justify-between flex-wrap py-2">
				<p class="font-medium px-3 py-1">
					{{ capitalize(bevanda.info.nome) }}
					{{ capitalize(bevanda.info.litri) }}L
				</p>
				<p class="font-medium px-3 py-1">€ {{ bevanda.info.prezzo }}</p>
			</div>
		</div>
		<button class="p-1 mx-5" @click="cartStore.togliBevanda(index)">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				class="stroke-black stroke-2"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<line x1="18" y1="6" x2="6" y2="18" />
				<line x1="6" y1="6" x2="18" y2="18" />
			</svg>
		</button>
	</div>
</template>

<script>
import { useCartStore } from "@/stores/cartStore";
import { capitalize } from "@/scripts/utils";

export default {
	name: "CardBevandaRiepilogo",
	props: {
		bevanda: Object,
		index: Number,
	},
	data() {
		return {
			cartStore: useCartStore(),
		};
	},
	methods: {
		capitalize,
	},
};
</script>