<template>
	<div
		class="
			rounded-xl
			shadow-xl
			grid grid-rows-10 grid-cols-3
			p-2
			w-full
			h-[376px]
			border border-shadow
			cursor-pointer
		"
		@click="cartStore.aggiungiBevanda(bevanda)"
	>
		<p class="row-span-1 col-span-1">{{ bevanda.litri }}L</p>
		<p
			class="row-span-1 col-span-2 justify-self-end"
			v-show="
				cartStore.carrelloBevande.find((item) => bevanda.id == item.id)
			"
		>
			x{{
				cartStore.carrelloBevande.filter(
					(item) => bevanda.id == item.id
				).length
			}}
		</p>
		<picture
			class="
				col-span-3
				row-span-5
				object-contain
				overflow-hidden
				p-2
				max-w-full max-h-full
				flex
				justify-center
				items-center
			"
		>
			<img
				:src="bevanda.foto || '/images/bevande/drink-default.png'"
				class="max-h-full max-w-full object-contain"
			/>
		</picture>
		<p
			class="font-medium col-span-2 p-1 self-center"
			:class="{
				'row-span-2': !bevanda.descrizione.length,
				'row-span-1': bevanda.descrizione.length,
			}"
		>
			{{ capitalize(bevanda.nome) }}
		</p>
		<p
			class="col-span-1 p-1 self-center"
			:class="{
				'row-span-2': !bevanda.descrizione.length,
				'row-span-1': bevanda.descrizione.length,
			}"
		>
			€ {{ bevanda.prezzo }}
		</p>
		<p
			v-if="bevanda.descrizione.length"
			class="text-1s col-span-3 row-span-2 self-center"
		>
			{{ bevanda.descrizione }}
		</p>
		<p
			class="col-span-3 self-center justify-self-center font-medium"
			:class="{
				'row-span-2': !bevanda.descrizione.length,
				'row-span-1': bevanda.descrizione.length,
			}"
		>
			Aggiungi
		</p>
	</div>
</template>

<script>
import { useCartStore } from "@/stores/cartStore";
import { capitalize } from "../scripts/utils";

export default {
	name: "CardBevanda",
	props: {
		bevanda: Object,
	},
	data() {
		return {
			cartStore: useCartStore(),
		};
	},
	methods: {
		capitalize,
	},
};
</script>