<template>
	<label :for="name" class="font-medium text-0x mt-0">{{ label }}</label>
	<div class="mb-5 mt-1 relative cursor-pointer">
		<select
			:name="name"
			:id="name"
			v-model.number="value"
			class="
				h-[43.2px]
				text-0x
				px-4
				py-1
				w-full
				rounded-md
				border border-grey-light
				focus:border-grey focus:shadow-lg
				bg-white
				appearance-none
                cursor-pointer
			"
		>
			<option
				:value="negozio.id"
				v-for="negozio of negozi"
				:key="negozio.id"
			>
				{{ capitalize(negozio.nome) }}
			</option>
		</select>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			viewBox="0 0 24 24"
			class="stroke-black stroke-[1.5] absolute top-1/2 right-2 -translate-y-1/2"
			fill="none"
			stroke-linecap="round"
			stroke-linejoin="round"
		>
			<path stroke="none" d="M0 0h24v24H0z" fill="none" />
			<polyline points="6 9 12 15 18 9" />
		</svg>
	</div>
</template>

<script>
import { capitalize } from "@/scripts/utils";

export default {
	name: "CustomSelect",
	props: {
		negozi: Array,
		label: String,
		name: String,
	},
	data() {
		return {
			value: 0,
		};
	},
	watch: {
		negozi(newValue, oldValue) {
			if (this.value == 0) {
				this.value = this.negozi[0]?.id || 0;
			}
		},
	},
	methods: {
		capitalize,
	},
};
</script>
