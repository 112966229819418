<template>
	<alert :show="showAlert" mode="drink" message="Sicuro di non volere niente da bere?"
		@close="this.$router.push({ name: 'scegliOrario' })" @router-drink="scegliBevande" />
	<div class="order-page px-5 overflow-y-auto pb-12">
		<div class="flex justify-end">
			<p class="font-medium py-2">Totale: € {{ totale }}</p>
		</div>
		<h2 class="font-medium text-1x py-2">Riepilogo</h2>
		<p class="mt-2" v-if="!cartStore.carrelloPizze.length &&
			!cartStore.carrelloBevande.length
			">
			Il carrello è vuoto
		</p>
		<transition-group name="item" tag="div" class="relative">
			<card-pizza-riepilogo v-for="(pizza, index) of carrelloPizzeCompleto" :pizza="pizza" :index="index"
				:key="pizza.key">
			</card-pizza-riepilogo>
			<card-bevanda-riepilogo v-for="(bevanda, index) of carrelloBevandeCompleto" :bevanda="bevanda" :index="index"
				:key="bevanda.key"></card-bevanda-riepilogo>
		</transition-group>
	</div>
	<button class="bg-red rounded-full fixed bottom-4 right-4 shadow-md" @click="scegliOrario">
		<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 73 73">
			<g id="Group_6" data-name="Group 6" transform="translate(-671 -777)">
				<path id="Union_3" data-name="Union 3"
					d="M-1935.212,17.052a3.5,3.5,0,0,1,0-4.95l5.339-5.339h-13.864a3.5,3.5,0,0,1-3.5-3.5,3.5,3.5,0,0,1,3.5-3.5h13.864l-5.339-5.339a3.5,3.5,0,0,1,0-4.95,3.5,3.5,0,0,1,4.949,0L-1918.948.788a3.5,3.5,0,0,1,0,4.95l-11.314,11.314a3.488,3.488,0,0,1-2.475,1.025A3.491,3.491,0,0,1-1935.212,17.052Z"
					transform="translate(2640.079 810.237)" class="fill-white" />
			</g>
		</svg>
	</button>
</template>

<script>
import { useCache } from "@/stores/cache";
import { useCartStore } from "@/stores/cartStore";
import { capitalize } from "@/scripts/utils";
import Dinero from "dinero.js";
import CardPizzaRiepilogo from "@/components/CardPizzaRiepilogo.vue";
import CardBevandaRiepilogo from "@/components/CardBevandaRiepilogo.vue";
import Alert from "@/components/Alert.vue";

Dinero.defaultCurrency = "EUR";
Dinero.globalFormat = "0.00";

export default {
	name: "Riepilogo",
	components: {
		CardPizzaRiepilogo,
		CardBevandaRiepilogo,
		Alert,
	},
	data() {
		return {
			cartStore: useCartStore(),
			pizze: new Map(),
			aggiunte: new Map(),
			bevande: new Map(),
			showAlert: false,
		};
	},
	computed: {
		carrelloPizzeCompleto() {
			if (!this.pizze.size || !this.aggiunte.size) return [];
			let pizze = JSON.parse(
				JSON.stringify(this.cartStore.carrelloPizze)
			);
			for (let pizza of pizze) {
				pizza.info = this.pizze.get(pizza.id);
				let prezzoTotale = Dinero();
				prezzoTotale = prezzoTotale.add(
					Dinero({ amount: +pizza.info.prezzo.replace(".", "") })
				);
				if (pizza.tipo == "baby") {
					prezzoTotale = prezzoTotale.subtract(
						Dinero({ amount: 50 })
					);
				}
				if (pizza.tipo == "maxi") {
					prezzoTotale = prezzoTotale.multiply(2).add(Dinero({ amount: 200 }));
				}
				if (pizza.impasto == "integrale") {
					prezzoTotale = prezzoTotale.add(Dinero({ amount: 100 }));
				}
				if (pizza.doppiapasta) {
					prezzoTotale = prezzoTotale.add(Dinero({ amount: 150 }));
				}
				if (pizza.aggiunte) {
					for (let aggiunta of pizza.aggiunte) {
						aggiunta.info = this.aggiunte.get(aggiunta.id);
						let prezzoAggiunta = Dinero({
							amount: +aggiunta.info.prezzo.replace(".", ""),
						});
						if (pizza.tipo == "maxi") prezzoAggiunta = prezzoAggiunta.multiply(2);
						prezzoTotale = prezzoTotale.add(
							prezzoAggiunta
						);
					}
				}
				pizza.prezzoTotale = prezzoTotale.toFormat("0.00");
			}
			return pizze;
		},
		carrelloBevandeCompleto() {
			if (!this.bevande.size) return [];
			return this.cartStore.carrelloBevande.map((bevanda) => ({
				...bevanda,
				info: this.bevande.get(bevanda.id),
			}));
		},
		totale() {
			let totale = Dinero();
			for (let pizza of this.carrelloPizzeCompleto) {
				totale = totale.add(
					Dinero({ amount: +pizza.prezzoTotale.replace(".", "") })
				);
			}
			for (let bevanda of this.carrelloBevandeCompleto) {
				totale = totale.add(
					Dinero({ amount: +bevanda.info.prezzo.replace(".", "") })
				);
			}
			return totale.toFormat("0.00");
		},
	},
	methods: {
		capitalize,
		scegliOrario() {
			if (this.carrelloBevandeCompleto.length == 0) {
				this.showAlert = true;
			} else {
				this.$router.push({ name: "scegliOrario" });
			}
		},
		scegliBevande() {
			this.cartStore.sezioneAttiva = "bevande";
			this.$router.push({ name: "scegliOrdine" });
		},
	},
	async created() {
		let data;
		try {
			let cache = useCache();
			let data = await cache.fetch(
				`/utenti/menu/${this.cartStore.negozioCorrente}`
			);
			data.pizze.forEach((pizza) => this.pizze.set(pizza.id, pizza));
			data.aggiunte.forEach((aggiunta) =>
				this.aggiunte.set(aggiunta.id, aggiunta)
			);
			data.bevande.forEach((bevanda) =>
				this.bevande.set(bevanda.id, bevanda)
			);
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style scoped>
.item-move,
.item-enter-active,
.item-leave-active {
	transition: all 0.5s ease;
}

.item-enter-from,
.item-leave-to {
	opacity: 0 !important;
	transform: translateX(30px) !important;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.item-leave-active {
	position: absolute;
}
</style>