<template>
	<label :for="name" class="font-medium text-0x mt-0">{{ label }}</label>
	<vue-tel-input
		v-if="type == 'tel'"
		v-model="phone"
		@on-input="onPhoneInput"
		@blur="checkValidPhone"
		class="
			mt-1
			text-0x
			px-3
			py-1
			rounded-md
			border border-grey-light
			focus:border-grey focus:shadow-lg
			focus-within:border-grey focus-within:shadow-lg
		"
		:class="{ invalid: !valid }"
		defaultCountry="IT"
		:inputOptions="inputOptions"
		:autoDefaultCountry="false"
	></vue-tel-input>
	<div
		v-else
		:class="{ invalid: !valid }"
		class="
			flex
			h-[43.2px]
			mt-1
			px-4
			py-1
			rounded-md
			border border-grey-light
			focus:border-grey focus:shadow-lg
		"
	>
		<input
			:id="name"
			:name="name"
			:type="type"
			v-model.trim="value"
			@input="onInput"
			@blur="checkValidInput"
			class="text-0x w-full"
		/>
		<div
			class="self-center cursor-pointer ml-2"
			@click="type = type == 'password' ? 'text' : 'password'"
		>
			<svg
				v-if="type == 'password'"
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="25"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				class="stroke-black"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<line x1="3" y1="3" x2="21" y2="21" />
				<path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
				<path
					d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"
				/>
			</svg>
			<svg
				v-else
				xmlns="http://www.w3.org/2000/svg"
				width="25"
				height="25"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				class="stroke-black"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<circle cx="12" cy="12" r="2" />
				<path
					d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
				/>
			</svg>
		</div>
	</div>
	<p class="text-red text-1s mt-1 self-end opacity-0">{{ error }}</p>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

export default {
	name: "CustomInput",
	components: {
		VueTelInput,
	},
	props: {
		label: String,
		name: String,
		type: String,
	},
	data() {
		return {
			phone: "",
			valid: true,
			value: "",
			formatErrors: {
				telefono: "Il telefono inserito non è corretto",
				nome: "Il nominativo inserito non è corretto",
				email: "L'email inserita non è corretta",
				password: "La password deve contenere almeno 8 caratteri",
			},
			error: "",
			errorType: "format", //format, empty
			phoneObject: null,
			inputOptions: {
				id: "telefono",
				name: "telefono",
				placeholder: "",
				maxlength: 25,
				type: "tel",
				showDialCode: false,
			},
		};
	},
	methods: {
		checkValidPhone() {
			if (this.errorType == "empty" && !this.phone.length) return;
			this.errorType = "format";
			this.error = this.formatErrors[this.name];
			this.valid = !this.phone.length || this.phoneObject.valid;
		},
		checkValidEmail() {
			this.valid =
				!this.value.length ||
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
					this.value
				);
		},
		checkValidPassword() {
			this.valid = !this.value.length || this.value.length >= 8;
		},
		checkValidName() {
			this.valid =
				!this.value.length ||
				/^([a-zàèéìòù]+)?? ?([a-zàèéìòù]+)?? ?(['-])? ?([a-zàèéìòù]+)$/i.test(
					this.value
				);
		},
		checkValidInput() {
			if (this.errorType == "empty" && !this.value.length) return;
			this.errorType = "format";
			this.error = this.formatErrors[this.name];
			switch (this.name) {
				case "email":
					this.checkValidEmail();
					break;
				case "password":
					this.checkValidPassword();
					break;
				case "nome":
					this.checkValidName();
					break;
				default:
					break;
			}
		},
		onPhoneInput(str, phoneObj) {
			this.phoneObject = phoneObj;
			this.value = phoneObj.number;
			if (!this.valid) this.checkValidPhone();
		},
		onInput() {
			if (!this.valid) this.checkValidInput();
		},
	},
	watch: {
		phone(value, oldValue) {
			const match = /^[()\-+0-9\s]*$/.test(value);
			if (!match) {
				this.$nextTick(() => (this.phone = oldValue));
			} else {
				this.phone = value;
			}
		},
	},
	created() {
		this.error = this.formatErrors[this.name];
	},
};
</script>