import axios from 'axios';
import { isLoggedIn } from "@/scripts/utils";
import { useUserStore } from "@/stores/userStore";
import { useNegozioStore } from "@/stores/negozioStore";

let http = axios.create({
    baseURL: 'https://api.spizzati.com/v1',
    validateStatus: function (status) {
        return (status >= 200 && status < 300) || status == 304;
    }
});



http.interceptors.request.use(function (config) {
    if (isLoggedIn() == 'loggedIn') {
        config.headers.Authorization = useUserStore().authToken || useNegozioStore().authToken;
    }
    return config;
});

export default http;