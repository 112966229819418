<template>
	<div
		class="
			top-0
			left-0
			min-w-[100vw] min-h-[100vh]
			w-[100vw]
			h-[100vh]
			bg-transparent
			flex
			justify-center
			items-center
			p-0
			absolute
			z-10
		"
		:class="{ flex: show, hidden: !show }"
	>
		<div
			class="
				w-full
				h-full
				absolute
				-z-10
				bg-grey-light bg-opacity-30
				blur-lg
			"
		></div>
		<div
			class="
				min-w-[250px] min-h-[300px]
				md:w-1/3
				w-1/2
				p-4
				bg-white
				text-neutral
				flex flex-col
				items-center
				rounded-lg
			"
		>
			<svg
				v-if="mode == 'success'"
				xmlns="http://www.w3.org/2000/svg"
				width="60"
				height="60"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				class="stroke-blue my-6"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path d="M5 12l5 5l10 -10" />
			</svg>
			<svg
				v-if="mode == 'error'"
				xmlns="http://www.w3.org/2000/svg"
				width="60"
				height="60"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				class="stroke-red my-6"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<line x1="18" y1="6" x2="6" y2="18" />
				<line x1="6" y1="6" x2="18" y2="18" />
			</svg>
			<svg
				v-if="mode == 'drink'"
				xmlns="http://www.w3.org/2000/svg"
				width="60"
				height="60"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				class="stroke-blue my-6"
				fill="none"
				stroke-linecap="round"
				stroke-linejoin="round"
			>
				<path stroke="none" d="M0 0h24v24H0z" fill="none" />
				<path
					d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"
				/>
				<line x1="12" y1="19" x2="12" y2="19.01" />
			</svg>
			<p class="text-center">{{ message }}</p>
			<div class="mt-14 flex justify-center w-full flex-wrap">
				<button
					class="font-medium rounded-md px-4 py-2 mx-1 my-2"
					:class="{
						'bg-white text-black border border-grey-light':
							mode == 'drink',
						'bg-red text-white': mode != 'drink',
					}"
					@click="$emit('close')"
				>
					{{ mode == "drink" ? "Prosegui" : "Chiudi" }}
				</button>
				<button
					v-if="mode == 'drink'"
					@click="$emit('router-drink')"
					class="
						bg-red
						text-white
						font-medium
						rounded-md
						px-4
						py-2
						mx-1
						my-2
					"
				>
					Scegli bevande
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Alert",
	emits: ["close", "router-drink"],
	props: {
		show: Boolean,
		message: String,
		mode: String, //error, success, drink
	},
};
</script>