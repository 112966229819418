import { useUserStore } from "@/stores/userStore";
import { useNegozioStore } from "@/stores/negozioStore";

function isLoggedIn(audience = "") {
	let token = useUserStore().authToken || useNegozioStore().authToken || false;
	let audienceFlag = !!audience.length;
	if (token) {
		let payload = getJwtPayload(token);
		if (new Date() > new Date(payload.exp * 1000)) {
			localStorage.clear();
			return 'loggedOut';
		} else if (audience != payload.aud && audienceFlag) {
			return 'audMismatch';
		} else {
			return 'loggedIn';
		}
	} else {
		return 'loggedOut';
	}
}

function getJwtPayload(token = useUserStore().authToken || useNegozioStore().authToken || false) {
	return token ? JSON.parse(
		atob(
			token.split('.')[1]
		)
	) : { aud: 'utente' };
}

function getJwtAudience() {
	return getJwtPayload().aud;
}

function capitalize(string) {
	return string[0].toLocaleUpperCase('it') + string.slice(1);
}

function formatTime(IsoString) {
	let date = new Date(IsoString);
	let padding = (string) => ('0' + string).slice(-2);
	return `${padding(date.getHours())}:${padding(date.getMinutes())}`;
}

function getISODay() {
	return ((new Date()).getDay() + 6) % 7;
}

function formatStoreOpening(openingHours) {
	let formatInterval = (string) => string.split(':').slice(0, 2).join(':');
	let intervals = openingHours[getISODay()];
	return intervals.length ? intervals.map(el => `${formatInterval(el.inizio)}-${formatInterval(el.fine)}`).join(' ') : 'Chiuso';
}

function getSuccessives(el) {
	let list = [];
	let nextSibling = el.nextElementSibling;
	while (nextSibling) {
		list.push(nextSibling);
		nextSibling = nextSibling.nextElementSibling;
	}
	return list;
}

function getHtmlPrint(template) {
	let newPage = `
		<html>
			<head>
	       		<style>
	                body {
	                			font-size: 16px;
	                			font-weight: bold;
								margin-top: 32px;
								margin-bottom: 32px;
	            			}

	            			.center {
	                			display: block;
	                			margin: 0 auto;
	                			text-align: center;
	            			}

	            			page {
	                			background: #f8f8f8;
	                			display: block;
	                margin: 0 auto;
	                margin-bottom: 0.5cm;
	                padding: 50px 30px 50px 30px;
	            }
	            page[size="stampa"] {
	                width: 80mm;
	                height: auto;
	            }
	            @media print {
	                @page{
	                    size: auto;
	                    margin: 0mm;
	                    width: 80mm;
	                    height: auto;
	                }
	                body, page {
	                    margin:0;
	                    box-shadow: 0;
	                    width: 80mm;
	                    height: auto;
	                }
	            }
	        </style>
	    </head>
	    <body onafterprint="self.close()">
			<page size="stampa">
				${template}
	        </page>
	    </body>
        <script type="text/javascript">
        window.print();
       </script>
				</html>
			`;
	return newPage;
}

const socketIoUrl = 'https://api.spizzati.com';
const socketIoPath = '/v1/socket-io';

export { isLoggedIn, getJwtAudience, capitalize, formatStoreOpening, formatTime, socketIoUrl, socketIoPath, getSuccessives, getHtmlPrint };