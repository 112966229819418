<template>
  <audio preload="auto" ref="audio">
    <source src="../assets/alarm3.mp3" type="audio/mpeg" />
  </audio>
  <div class="text-black mx-5">
    <div class="flex justify-start items-center font-medium py-6">
      <p>{{ capitalize(nomeNegozio) }}</p>
    </div>
    <div class="pb-4">
      <button @click="mostra = 'ordini'" class="p-2 border border-shadow shadow-md rounded-md bg-white mr-3"
        :class="{ 'text-red': mostra == 'ordini' }">
        Ordini
      </button>
      <button @click="mostra = 'cestino'" class="p-2 border border-shadow shadow-md rounded-md bg-white"
        :class="{ 'text-red': mostra == 'cestino' }">
        Cestino
      </button>
    </div>
    <div v-show="mostra == 'ordini'">
      <h2 class="font-medium text-1x mb-4" v-if="ordiniNonConfermati.length">Nuovi Ordini</h2>
      <card-ordine-negozio v-for="ordine of ordiniNonConfermati" :key="ordine.id" :ordine="ordine"
        @stampa="clickBottoneStampa(ordine)" @conferma="confermaOrdine(ordine)" @rifiuta="rifiutaOrdine(ordine)"
        @conferma-e-stampa="confermaEStampa(ordine)">
      </card-ordine-negozio>
      <h2 class="font-medium text-1x mb-4">Ordini Confermati</h2>
      <p v-if="ordiniConfermati.length == 0">Ancora nessun ordine</p>
      <div v-else>
        <card-ordine-negozio v-for="ordine of ordiniConfermati" :key="ordine.id" :ordine="ordine"
          @stampa="clickBottoneStampa(ordine)" @conferma="confermaOrdine(ordine)" @rifiuta="rifiutaOrdine(ordine)">
        </card-ordine-negozio>
      </div>
    </div>
    <div v-show="mostra == 'cestino'">
      <h2 class="font-medium text-1x mb-4">Ordini rifiutati</h2>
      <p v-if="ordiniRifiutati.length == 0">Nessun ordine rifiutato</p>
      <div v-else>
        <card-ordine-negozio v-for="ordine of ordiniRifiutati" :key="ordine.id" :ordine="ordine"
          @stampa="clickBottoneStampa(ordine)" @conferma="confermaOrdine(ordine)" @rifiuta="rifiutaOrdine(ordine)">
        </card-ordine-negozio>
      </div>
    </div>
  </div>
  <template ref="templateScontrino">
    <div size="stampa" v-if="ordineDaStampare">
      <p style="float: left">{{ capitalize(nomeNegozio) }}</p>
      <p style="float: right">
        {{ new Date(ordineDaStampare.prenotatoPerLe).toLocaleDateString("it") }}
      </p>
      <br style="clear: both" />
      <p class="center">RITIRA</p>
      <p class="center">
        --------------------------------------------------------
      </p>
      <div>
        <div>
          <div style="float: left">
            #{{ ordineDaStampare.id }}
            {{ ordineDaStampare.nominativo }}
          </div>
          <div style="float: right">
            Ritiro:
            {{
              new Date(ordineDaStampare.prenotatoPerLe).toLocaleTimeString(
                "it",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            }}
          </div>
          <br style="clear: both" />
        </div>
      </div>
      <p>
        {{
          new Date().toLocaleTimeString("it", {
            hour: "2-digit",
            minute: "2-digit",
          })
        }}
      </p>
      <p>Tel: {{ ordineDaStampare.telefono }}</p>
      <p class="center" v-if="ordineDaStampare?.nota?.length">
        --------------------------------------------------------
      </p>
      <p class="center" v-if="ordineDaStampare?.nota?.length">Nota</p>
      <p v-if="ordineDaStampare?.nota?.length">
        {{ ordineDaStampare.nota }}
      </p>
      <p class="center">
        --------------------------------------------------------
      </p>
      <p v-for="(pizza, index) in ordineDaStampare.contenuto.pizze" :key="index">
        1 {{ capitalize(pizza.tipo) }} {{ capitalize(pizza.nome) }}<br />
        <span v-if="pizza.impasto == 'integrale'"> INTEGRALE ***<br /></span>
        <span v-if="pizza.doppiapasta"> DOPPIAPASTA ***<br /></span>
        <span v-for="(senza, index2) in pizza.senza" :key="index2">
          - (-) {{ capitalize(senza) }}<br />
        </span>
        <span v-for="(aggiunta, index3) in pizza.aggiunte" :key="index3">
          - (+) {{ capitalize(aggiunta.nome) }}<br />
        </span>
      </p>
      <p v-for="(bevanda, index) in ordineDaStampare.contenuto.bevande" :key="index">
        1 {{ capitalize(bevanda.nome) }} {{ bevanda.litri }}L
      </p>
      <p class="center">
        --------------------------------------------------------
      </p>
      <div>
        <p class="center">Impasto</p>
        <div>
          <div style="float: left" v-if="numeroNormale">PICCOLA</div>
          <div style="float: right" v-if="numeroNormale">
            {{ numeroNormale }}
          </div>
          <br style="clear: both" v-if="numeroNormale" />
          <div style="float: left" v-if="numeroMaxi">MAXI</div>
          <div style="float: right" v-if="numeroMaxi">
            {{ numeroMaxi }}
          </div>
          <br style="clear: both" v-if="numeroMaxi" />
        </div>
      </div>
      <p class="center">
        --------------------------------------------------------
      </p>
      <div>
        <div>
          <div style="float: left">Importo da pagare</div>
          <div style="float: right">
            {{ ordineDaStampare.totale }}
          </div>
          <br style="clear: both" />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import http from "@/scripts/axios-config";
import { useNegozioStore } from "@/stores/negozioStore";
import CardOrdineNegozio from "@/components/CardOrdineNegozio.vue";
import {
  capitalize,
  socketIoUrl,
  socketIoPath,
  getHtmlPrint,
} from "@/scripts/utils.js";
import { io } from "socket.io-client";

export default {
  name: "Negozio",
  components: {
    CardOrdineNegozio,
  },
  data() {
    return {
      ordini: [],
      nomeNegozio: "",
      mostra: "ordini", //ordini o cestino
      ordineDaStampare: null,
      codaRichieste: new Set(), //ordini che sono in attesa di conferma o rifiuto
      codaDiStampa: [],
      showDialog: false,
    };
  },
  computed: {
    ordiniIds() {
      return new Set(this.ordini.map((x) => x.id));
    },
    ordiniNonConfermati() {
      return this.ordini.filter((x) => x.stato == "non confermato");
    },
    ordiniConfermati() {
      return this.ordini.filter((x) => x.stato == "confermato");
    },
    ordiniRifiutati() {
      return this.ordini.filter((x) => x.stato == "rifiutato");
    },
    numeroMaxi() {
      if (!this.ordineDaStampare) return 0;
      return this.ordineDaStampare.contenuto.pizze.filter(pizza => pizza.tipo === 'maxi').length;
    },
    numeroNormale() {
      if (!this.ordineDaStampare) return 0;
      return this.ordineDaStampare.contenuto.pizze.filter(pizza => ['normale', 'baby'].includes(pizza.tipo)).length;
    }
  },
  /*watch: {
    ordiniNonConfermati(newValue, oldValue) {
      if (newValue.length && !oldValue.length) {
        this.showDialog = true;
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
      } else if (!newValue.length && oldValue.length) {
        this.showDialog = false;
        this.$refs.audio.pause();
      }
    },
  },*/
  methods: {
    capitalize,
    async confermaOrdine(ordine, exception = false) {
      if (this.codaRichieste.has(ordine.id) || ordine.stato == "confermato")
        return;
      this.codaRichieste.add(ordine.id);
      try {
        await http.put("/negozi/ordini", {
          idOrdine: ordine.id,
          stato: "confermato",
        });
        ordine.stato = "confermato";
      } catch (error) {
        console.log(error);
        if (exception) {
          throw error;
        }
      } finally {
        this.codaRichieste.delete(ordine.id);
      }
    },
    async rifiutaOrdine(ordine) {
      if (this.codaRichieste.has(ordine.id) || ordine.stato == "rifiutato")
        return;
      this.codaRichieste.add(ordine.id);
      try {
        await http.put("/negozi/ordini", {
          idOrdine: ordine.id,
          stato: "rifiutato",
        });
        ordine.stato = "rifiutato";
      } catch (error) {
        console.log("Errore");
      } finally {
        this.codaRichieste.delete(ordine.id);
      }
    },
    async stampaOrdini() {
      if (!this.codaDiStampa.length) return;
      console.log('Stampa');
      this.ordineDaStampare = this.codaDiStampa.shift();
      await this.$nextTick();
      let page = getHtmlPrint(
        this.$refs.templateScontrino.firstChild.outerHTML
      );
      let finestraScontrino = window.open(
        "",
        "_blank",
        "status=1,width=500,height=600"
      );
      finestraScontrino.document.write(page);
      this.confermaOrdine(this.ordineDaStampare);
    },
    async clickBottoneStampa(ordine) {
      if (this.codaDiStampa.includes(ordine)) return;
      this.codaDiStampa.push(ordine);
      this.stampaOrdini();
    },
    async confermaEStampa(ordine) {
      try {
        await this.confermaOrdine(ordine, true);
        await this.clickBottoneStampa(ordine);
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    let negozio = useNegozioStore();
    this.nomeNegozio = negozio.nome;
    try {
      let response = await http.get("/negozi/ordini");
      this.ordini = response.data;
    } catch (error) {
      console.log("Errore");
    }
    let ordiniDaStampare = this.ordini.filter(
      (x) => x.stato == "non confermato"
    );
    console.log(this.ordini);
    if (ordiniDaStampare.length) {
      this.$refs.audio.play();
      this.codaDiStampa = this.codaDiStampa.concat(ordiniDaStampare);
      this.stampaOrdini();
    }
    const socket = io(socketIoUrl, {
      path: socketIoPath,
      auth: {
        token: negozio.authToken,
      },
    });
    setInterval(this.stampaOrdini, 1000 * 10);
    socket.on("nuovo ordine", (ordine) => {
      if (!this.ordiniIds.has(ordine.id)) {
        this.ordini.push(ordine);
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.play();
        this.codaDiStampa.push(ordine);
        this.stampaOrdini();
      }
    });
    /*this.$refs.audio.addEventListener(
      "ended",
      function () {
        this.currentTime = 0;
        this.play();
      },
      false
    );*/
  },
};
</script>
