<template>
	<div
		class="
			flex flex-col
			rounded-xl
			shadow-xl
			border border-shadow
			p-2
			max-w-[300px]
		"
		@click="clickCard"
	>
		<p
			class="font-medium m-2"
			:class="{ 'text-red': picked == negozio.id }"
		>
			{{ capitalize(negozio.nome) }}
		</p>
		<p class="mx-2 mb-1">
			{{ capitalize(weekday) }}: {{ formatStoreOpening(negozio.orario) }}
		</p>
		<p class="mx-2 mb-1">
			Ordini: {{ ordiniDiOggi }}/{{ negozio.maxOrdiniUtente }}
		</p>
		<p class="mx-2 mb-1">{{ negozio.indirizzo }}</p>
	</div>
</template>

<script>
import { capitalize, formatStoreOpening } from "@/scripts/utils.js";
import { useUserStore } from "@/stores/userStore";

export default {
	name: "CardNegozio",
	props: {
		negozio: Object,
		picked: Number,
	},
	emits: ["picked"],
	computed: {
		weekday() {
			let today = new Date();
			return today.toLocaleDateString("it", { weekday: "long" });
		},
		ordiniDiOggi() {
			let user = useUserStore();
			let idNegozio = this.negozio.id;
			return user.ordini.filter((x) => x.idNegozio == idNegozio).length;
		},
	},
	methods: {
		capitalize,
		formatStoreOpening,
		clickCard() {
			if (this.ordiniDiOggi < this.negozio.maxOrdiniUtente)
				this.$emit("picked");
		},
	},
};
</script>