<template>
    <picture>
        <!-- WebP source -->
        <source :srcset="url + '.webp'" type="image/webp">
        <!-- Fallback image -->
        <img :src="url + '.png'" class="h-full max-w-full object-contain" />
    </picture>
</template>

<script>
export default {
    props: {
        url: String,
    },
}
</script>