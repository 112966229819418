<template>
	<div class="mx-5 flex flex-col order-page">
		<h1 class="font-medium text-2x">Punto vendita</h1>
		<p class="my-8 self-center">Scegli un punto vendita</p>
		<form action="" class="flex flex-col" @submit.prevent="scegliNegozio">
			<card-negozio
				v-for="negozio in negozi"
				:key="negozio.id"
				:negozio="negozio"
				:picked="picked"
				@picked="picked = negozio.id"
			>
			</card-negozio>
			<button
				type="submit"
				class="
					disabled:bg-grey-light
					bg-red
					fixed
					bottom-4
					right-4
					shadow-md
					rounded-full
				"
				:disabled="picked == -1"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="42"
					height="42"
					viewBox="0 0 73 73"
				>
					<g
						id="Group_6"
						data-name="Group 6"
						transform="translate(-671 -777)"
					>
						<path
							id="Union_3"
							data-name="Union 3"
							d="M-1935.212,17.052a3.5,3.5,0,0,1,0-4.95l5.339-5.339h-13.864a3.5,3.5,0,0,1-3.5-3.5,3.5,3.5,0,0,1,3.5-3.5h13.864l-5.339-5.339a3.5,3.5,0,0,1,0-4.95,3.5,3.5,0,0,1,4.949,0L-1918.948.788a3.5,3.5,0,0,1,0,4.95l-11.314,11.314a3.488,3.488,0,0,1-2.475,1.025A3.491,3.491,0,0,1-1935.212,17.052Z"
							transform="translate(2640.079 810.237)"
							class="fill-white"
						/>
					</g>
				</svg>
			</button>
		</form>
	</div>
</template>

<script>
import { useCache } from "@/stores/cache";
import { useCartStore } from "@/stores/cartStore";
import { capitalize, formatStoreOpening } from "@/scripts/utils.js";
import CardNegozio from "@/components/CardNegozio.vue";

export default {
	name: "ScegliNegozio",
	components: {
		CardNegozio,
	},
	emits: ["negozio-scelto"],
	data() {
		return {
			negozi: [],
			picked: -1,
			cartStore: useCartStore(),
		};
	},
	methods: {
		capitalize,
		formatStoreOpening,
		scegliNegozio() {
			this.cartStore.negozioCorrente = this.picked;
			this.$router.push({ name: "scegliOrdine" });
		},
	},
	async created() {
		this.cartStore.negozioCorrente = null;
		try {
			let cache = useCache();
			this.negozi = await cache.fetch("/utenti/lista-negozi");
		} catch (e) {
			console.log("Errore");
		}
	},
};
</script>