<template>
	<alert
		:show="showAlert"
		mode="error"
		message="Si è verificato un errore, riprova più tardi"
		@close="showAlert = false"
	/>
	<div class="order-page px-5">
		<h2 class="font-medium text-1x">Orario</h2>
		<div class="flex justify-center">
			<time-picker
				:allowedIntervals="orarioNegozio"
				@timeChange="(string) => (orario = string)"
			/>
		</div>
		<h2 class="font-medium text-1x">Nota</h2>
		<div class="flex justify-center py-5">
			<textarea
				v-model="nota"
				cols="30"
				rows="10"
				maxlength="200"
				class="border border-black resize-none p-2 max-w-full"
			></textarea>
		</div>
		<div class="flex justify-center py-5">
			<button
				@click="completaOrdine"
				class="
					bg-red
					p-1
					mt-4
					rounded-md
					w-fit
					text-white text-0x
					font-medium
					h-[43.2px]
				"
				:disabled="disabled"
			>
				Completa l'Ordine
			</button>
		</div>
	</div>
</template>

<script>
import TimePicker from "@/components/TimePicker.vue";
import Alert from "@/components/Alert.vue";
import { useCartStore } from "@/stores/cartStore";
import { useCache } from "@/stores/cache";
import { useUserStore } from "@/stores/userStore";
import http from "@/scripts/axios-config";

export default {
	name: "ScegliOrario",
	components: {
		TimePicker,
		Alert,
	},
	data() {
		return {
			orario: "",
			nota: "",
			disabled: false,
			orarioNegozio: [],
			showAlert: false,
		};
	},
	methods: {
		async completaOrdine() {
			let cartStore = useCartStore();
			let user = useUserStore();
			let ordine = JSON.parse(
				JSON.stringify(cartStore.carrelli[cartStore.negozioCorrente])
			);
			ordine.contenuto.pizze.forEach((x) => delete x.key);
			ordine.contenuto.bevande.forEach((x) => delete x.key);
			ordine.prenotatoPerLe = this.orario;
			if (ordine.nota) delete ordine.nota;
			if (ordine.contenuto.bevande.length == 0)
				delete ordine.contenuto.bevande;
			if (this.nota.length > 0) ordine.nota = this.nota;
			try {
				this.disabled = true;
				let response = await http.post("/utenti/ordini", ordine);
				cartStore.eliminaCarrello(cartStore.negozioCorrente);
				user.ordini.push(response.data);
				this.$router.push({ name: "home" });
				this.disabled = false;
			} catch (error) {
				console.log(error);
				this.showAlert = true;
			}
		},
	},
	async created() {
		try {
			let cache = useCache();
			let cartStore = useCartStore();
			let negozi = await cache.fetch("/utenti/lista-negozi");
			let negozio = negozi.find((x) => x.id == cartStore.negozioCorrente);
			if (!negozio) throw "Errore";
			let giorno = (new Date().getDay() + 6) % 7;
			this.orarioNegozio = negozio.orario[giorno].map((x) => ({
				start: x.inizio.split(":").map((x) => +x),
				end: x.fine.split(":").map((x) => +x),
			}));
		} catch (e) {
			console.log(e);
		}
	},
};
</script>