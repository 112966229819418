import { defineStore } from 'pinia';

export const usePreferitiStore = defineStore('preferiti', {
    state: () => {
        return {
            store: {}
        }
    },
    actions: {
        modificaPreferiti(idNegozio, pizza) {
            if (pizza.idNegozio != idNegozio) return;
            if (!this.store[idNegozio]) this.store[idNegozio] = new Set();
            if (!this.store[idNegozio].has(pizza.id)) {
				this.store[idNegozio].add(pizza.id);
			} else {
				this.store[idNegozio].delete(pizza.id);
			}
        },
        has(idNegozio, pizza) {
            if (pizza.idNegozio != idNegozio || !this.store[idNegozio]) return false;
            return this.store[idNegozio].has(pizza.id);
        }
    },
    persist: {
        serializer: {
            serialize: (state) => {
                let output = {};
                output.store = {};
                for(let [idNegozio, preferiti] of Object.entries(state.store)) {
                    output.store[idNegozio] = [...preferiti];
                }
                return JSON.stringify(output);
            },
            deserialize: (string) => {
                let json = JSON.parse(string);
                let output = {};
                output.store = {};
                for(let [idNegozio, preferiti] of Object.entries(json.store)) {
                    output.store[idNegozio] = new Set(preferiti);
                }
                return output;
            }
        }
    }
});