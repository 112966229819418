<template>
	<div class="
			fixed
			order-page
			w-full
			h-full
			bg-white
			top-[62px]
			left-0
			overflow-y-scroll overflow-x-hidden
			px-5
			origin-top-left
		" ref="finestraAggiunte">
		<div class="flex justify-center">
			<picture class="
					w-1/3
					min-w-[175px]
					max-w-[250px]
					flex
					justify-center
					items-center
					anime-target
				">
				<CustomImg :url="pizzaDaModificare.foto || '/images/pizza-test'" class="object-contain" />
			</picture>
		</div>
		<h2 class="font-medium text-1x my-3 anime-target">
			{{ capitalize(pizzaDaModificare.nome) }}
		</h2>
		<form action="">
			<fieldset class="anime-target">
				<legend class="font-medium py-3">Tipo</legend>
				<div class="flex">
					<div class="flex">
						<input type="radio" name="tipo" id="tipo-normale" value="normale" class="hidden peer"
							v-model="tipoScelto" />
						<label for="tipo-normale" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Normale</label>
					</div>
					<div class="flex">
						<input type="radio" name="tipo" id="tipo-baby" value="baby" class="hidden peer"
							v-model="tipoScelto" />
						<label for="tipo-baby" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Baby</label>
					</div>
					<div class="flex">
						<input type="radio" name="tipo" id="tipo-maxi" value="maxi" class="hidden peer"
							v-model="tipoScelto" />
						<label for="tipo-maxi" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Maxi</label>
					</div>
				</div>
			</fieldset>
			<fieldset class="anime-target">
				<legend class="font-medium py-3">Impasto</legend>
				<div class="flex">
					<div class="flex">
						<input type="radio" name="impasto" id="impasto-normale" value="normale" class="hidden peer"
							v-model="impastoScelto" />
						<label for="impasto-normale" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Normale</label>
					</div>
					<div :class="{
						flex: tipoScelto != 'maxi',
						hidden: tipoScelto == 'maxi',
					}">
						<input type="radio" name="impasto" id="impasto-integrale" value="integrale" class="hidden peer"
							v-model="impastoScelto" />
						<label for="impasto-integrale" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Integrale</label>
					</div>
				</div>
			</fieldset>
			<fieldset class="anime-target">
				<legend class="font-medium py-3">Doppiapasta</legend>
				<div class="flex">
					<div class="flex">
						<input type="radio" name="doppiapasta" id="doppiapasta-false" :value="false" class="hidden peer"
							v-model="doppiapastaScelta" />
						<label for="doppiapasta-false" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Normale</label>
					</div>
					<div :class="{
						flex: tipoScelto == 'normale',
						hidden: tipoScelto == 'baby' || tipoScelto == 'maxi',
					}">
						<input type="radio" name="doppiapasta" id="doppiapasta-true" :value="true" class="hidden peer"
							v-model="doppiapastaScelta" />
						<label for="doppiapasta-true" class="
								p-2
								mx-2
								rounded-md
								shadow-lg
								border border-shadow
								cursor-pointer
								peer-checked:text-red
							">Doppiapasta</label>
					</div>
				</div>
			</fieldset>
			<fieldset class="anime-target">
				<legend class="font-medium my-2">Ingredienti</legend>
				<div v-for="ingrediente of pizzaDaModificare.ingredienti" :key="ingrediente" class="py-2">
					<input type="checkbox" name="ingredienti" :id="ingrediente" :value="ingrediente" class="accent-red"
						v-model="ingredientiScelti" checked />
					<label :for="ingrediente" class="mx-2">{{
						capitalize(ingrediente)
					}}</label>
				</div>
			</fieldset>
			<fieldset class="anime-target">
				<legend class="font-medium my-2">Aggiunte</legend>
				<div class="py-2">
					<p v-if="!aggiunteScelte.length">Nessuna aggiunta scelta</p>
					<div v-for="aggiuntaScelta of aggiunteScelte" :key="aggiuntaScelta.id" class="py-2">
						<input type="checkbox" name="aggiunta-scelta" :id="aggiuntaScelta.id"
							:checked="aggiunteSelezionate.has(aggiuntaScelta)" @change="
								aggiunteSelezionate.has(aggiuntaScelta)
									? aggiunteSelezionate.delete(aggiuntaScelta)
									: aggiunteSelezionate.add(aggiuntaScelta)
								" class="accent-red" />
						<label :for="aggiuntaScelta.id" class="mx-2">{{
							capitalize(aggiuntaScelta.nome)
						}}</label>
					</div>
				</div>
			</fieldset>
		</form>
		<div class="py-12">
			<h2 class="font-medium text-1x py-2 anime-target">
				Lista Aggiunte
			</h2>
			<transition-group name="aggiunte" tag="div" class="relative">
				<div v-for="aggiunta of aggiunteNonSelezionate" :key="aggiunta.id" @click="scegliAggiunta(aggiunta)"
					class="flex py-3 align-center cursor-pointer anime-target">
					<p class="font-medium">{{ capitalize(aggiunta.nome) }}</p>
					<p class="mx-10">€ {{ tipoScelto == 'maxi' ? Number(aggiunta.prezzo * 2).toFixed(2) : aggiunta.prezzo }}
					</p>
				</div>
			</transition-group>
		</div>
		<div class="
				flex
				justify-center
				w-full
				sticky
				bottom-2
				left-0
				font-medium
			">
			<button class="
					rounded-lg
					shadow-lg
					p-3
					m-3
					bg-white
					anime-target
					border border-grey-light
				" @click="$emit('chiudi-finestra-aggiunte')">
				Chiudi
			</button>
			<button class="
					rounded-lg
					shadow-lg
					p-3
					m-3
					bg-white
					anime-target
					border border-grey-light
				" @click="
					cartStore.aggiungiPizza(
						pizzaDaModificare,
						tipoScelto,
						impastoScelto,
						doppiapastaScelta,
						[...ingredientiNonScelti],
						[...aggiunteSelezionate].map((aggiunta) => ({
							id: aggiunta.id,
						}))
					)
					">
				Aggiungi al carrello
			</button>
		</div>
	</div>
</template>

<script>
import { useCache } from "@/stores/cache";
import { useCartStore } from "@/stores/cartStore";
import { capitalize, getSuccessives } from "@/scripts/utils";
import anime from "animejs/lib/anime.es.js";
import CustomImg from "./CustomImg.vue";

export default {
	name: "FinestraAggiunte",
	components: {
		CustomImg,
	},
	props: {
		mostraAggiunte: Boolean,
		pizzaDaModificare: Object,
		cardOrigine: Object,
	},
	emits: ["chiudi-finestra-aggiunte"],
	data() {
		return {
			aggiunte: [],
			cartStore: useCartStore(),
			tipoScelto: "normale", //normale, baby, maxi
			impastoScelto: "normale", //normale integrale
			doppiapastaScelta: false,
			ingredientiScelti: [],
			aggiunteScelte: [],
			aggiunteSelezionate: new Set(),
			anim: null,
		};
	},
	computed: {
		aggiunteNonSelezionate() {
			return this.aggiunte.filter(
				(aggiunta) => !this.aggiunteSelezionate.has(aggiunta)
			);
		},
		ingredientiNonScelti() {
			return this.pizzaDaModificare.ingredienti.filter(
				(ingrediente) => !this.ingredientiScelti.includes(ingrediente)
			);
		},
	},
	watch: {
		mostraAggiunte(newValue, oldValue) {
			let finestraAggiunte = this.$refs.finestraAggiunte;
			let cardPosition = this.cardOrigine.getBoundingClientRect();
			let finestraPosition = finestraAggiunte.getBoundingClientRect();
			let translateX = cardPosition.x - finestraPosition.x;
			let translateY = cardPosition.y - finestraPosition.y;
			let scaleX = cardPosition.width / finestraPosition.width;
			let scaleY = cardPosition.height / finestraPosition.height;
			if (!oldValue && newValue) {
				this.ingredientiScelti = [
					...this.pizzaDaModificare.ingredienti,
				];
				this.aggiunteScelte = [];
				this.aggiunteSelezionate = new Set();
				this.tipoScelto = "normale";
				this.impastoScelto = "normale";
				this.doppiapastaScelta = false;
				finestraAggiunte.style.zIndex = 10;
				finestraAggiunte
					.querySelectorAll(".anime-target")
					.forEach((el) => (el.style.opacity = 0));
				this.anim = anime.timeline();
				this.anim.add({
					targets: finestraAggiunte,
					translateX: [translateX, 0],
					translateY: [translateY, 0],
					scaleX: [scaleX, 1],
					scaleY: [scaleY, 1],
					opacity: [0, 1],
					duration: 300,
					easing: "easeOutCubic",
				});
				this.anim.add({
					targets: finestraAggiunte.querySelectorAll(".anime-target"),
					scaleX: [0.4, 1],
					scaleY: [0.4, 1],
					opacity: 1,
					duration: 250,
					easing: "easeOutCubic",
				});
			} else if (oldValue && !newValue) {
				this.anim = anime.timeline();
				this.anim.add({
					targets: finestraAggiunte.querySelectorAll(".anime-target"),
					scaleX: [1, 0.4],
					scaleY: [1, 0.4],
					opacity: 0,
					duration: 250,
					easing: "easeInCubic",
				});
				this.anim.add({
					targets: finestraAggiunte,
					translateX,
					translateY,
					scaleX,
					scaleY,
					opacity: 0,
					duration: 300,
					easing: "easeInCubic",
					complete: (anim) => {
						finestraAggiunte.scrollTop = 0;
						finestraAggiunte.style.zIndex = -10;
						finestraAggiunte.style.transform = `
							translateX(0) 
							translateY(0) 
							scaleX(1) 
							scaleY(1) 
						`;
					},
				});
			}
		},
		doppiapastaScelta(newValue, oldValue) {
			if ((this.tipoScelto == "baby" || this.tipoScelto == "maxi") && newValue == true) this.doppiapastaScelta = false;
		},
		impastoScelto(newValue, oldValue) {
			if (this.tipoScelto == "maxi" && newValue == true) this.impastoScelto = false;
		},
		tipoScelto(newValue, oldValue) {
			if ((newValue == "baby" || newValue == "maxi") && this.doppiapastaScelta == true)
				this.doppiapastaScelta = false;
			if (newValue == "maxi" && this.impastoScelto == "integrale") this.impastoScelto = "normale";
		},
	},
	methods: {
		capitalize,
		scegliAggiunta(aggiunta) {
			this.aggiunteSelezionate.add(aggiunta);
			if (!this.aggiunteScelte.includes(aggiunta))
				this.aggiunteScelte.push(aggiunta);
		},
		/*
		onLeave(el, done) {
			let timeline = anime.timeline();
			let { height } = el.getBoundingClientRect();
			let successives = getSuccessives(el);
			timeline.add({
				targets: el,
				opacity: 0,
				scale: 0.1,
				duration: 400,
				easing: "easeInCubic",
			});
			timeline.add({
				targets: successives,
				translateY: -height,
				duration: 400,
				easing: "easeInOutCubic",
				complete: (anim) => {
					successives.forEach(
						(el) => (el.style.transform = "translateY(0)")
					);
					done();
				},
			});
		},
		onEnter(el, done) {
			anime({
				targets: el,
				opacity: [0, 1],
				scale: [0.1, 1],
				duration: 400,
				easing: "easeOutCubic",
				complete: (anim) => {
					done();
				},
			});
		},
		*/
	},
	async created() {
		try {
			let cache = useCache();
			let data = await cache.fetch(
				`/utenti/menu/${this.cartStore.negozioCorrente}`
			);
			this.aggiunte = data.aggiunte;
			this.$refs.finestraAggiunte.style.zIndex = -10;
		} catch (error) {
			console.log(error);
		}
	},
};
</script>

<style scoped>
.aggiunte-move,
/* apply transition to moving elements */
.aggiunte-enter-active,
.aggiunte-leave-active {
	transition: all 0.5s ease;
}

.aggiunte-enter-from,
.aggiunte-leave-to {
	opacity: 0 !important;
	transform: translateX(30px) !important;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.aggiunte-leave-active {
	position: absolute;
}
</style>