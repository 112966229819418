import { defineStore } from 'pinia';
import http from '@/scripts/axios-config';

export const useCache = defineStore('cache', {
    state: () => {
        return {
            store: {},
        }
    },
    actions: {
        async fetch(endpoint, update = false) {
            if (!this.store[endpoint] || update) {
                try {
                    let response = await http.get(endpoint);
                    this.store[endpoint] = {
                        data: response.data,
                        timestamp: (new Date()).toISOString()
                    }
                } catch (error) {
                    throw (error);
                }
            }
            return this.store[endpoint].data;
        },
        async checkUpdates() {
            try {
                let cache = this;
                let payload = Object.entries(this.store).map(x => ({ endpoint: x[0], timestamp: x[1].timestamp }));
                let response = await http.get('cache', {
                    params: {
                        value: JSON.stringify(payload)
                    }
                });
                await Promise.all(response.data.map(endpoint => cache.fetch(endpoint, true)));
            } catch (error) {
                throw (error);
            }
        },
        async init(endpoints) {
            try {
                let cache = this;
                await Promise.all(endpoints.map(endpoint => cache.fetch(endpoint)));
                await this.checkUpdates();
            } catch (error) {
                throw (error);
            }
        }
    },
    persist: true
});