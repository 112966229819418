<template>
	<div class="flex flex-col text-black">
		<div class="flex justify-end items-center font-medium py-6 mx-5">
			<p>{{ userStore.nominativo }}</p>
		</div>
		<h1 class="mx-5 font-medium text-2x">Ordini</h1>
		<p v-if="!userStore.ordini.length" class="px-5 pt-12 self-center">
			Non hai effettuato ancora nessun ordine oggi
		</p>
		<div v-else class="px-5 pb-16 pt-6">
			<div
				v-for="ordine in userStore.ordini"
				:key="ordine.id"
				class="border border-shadow p-4 rounded-lg shadow-lg mb-6"
			>
				<div
					class="
						flex
						font-medium
						justify-between
						flex-wrap
						items-center
					"
				>
					<div class="flex pr-4 pt-2">
						<p>{{ capitalize(ordine.nomeNegozio) }}</p>
						<p class="pl-4">
							ore {{ formatTime(ordine.prenotatoPerLe) }}
						</p>
					</div>
					<p class="pt-1">Totale: € {{ ordine.totale }}</p>
				</div>
				<p class="pt-1">Stato: {{ ordine.stato }}</p>
				<p v-if="ordine.nota" class="pt-1">Nota: {{ ordine.nota }}</p>
				<p class="font-medium pt-3">Pizze</p>
				<div
					v-for="(pizza, index) of ordine.contenuto.pizze"
					:key="index"
					class="py-1"
				>
					<div class="flex justify-between">
						<p>{{ capitalize(pizza.nome) }}</p>
						<p>€ {{ pizza.prezzo }}</p>
					</div>
					<div class="flex flex-wrap">
						<p v-if="pizza.tipo != 'normale'" class="pr-1">
							{{ pizza.tipo }}
						</p>
						<p
							v-if="pizza.impasto != 'normale'"
							class="pr-1 italic"
						>
							{{ pizza.impasto }}
						</p>
						<p v-if="pizza.doppiapasta" class="pr-1 italic">
							doppiapasta
						</p>
					</div>
					<p v-if="pizza?.senza?.length" class="px-3 py-2">
						Senza {{ pizza.senza.join(", ") }}
					</p>
					<p v-if="pizza?.aggiunte?.length" class="px-3 py-2">
						Con aggiunta di
						{{
							pizza.aggiunte
								.map((aggiunta) => aggiunta.nome)
								.join(", ")
						}}
					</p>
				</div>
				<p v-if="ordine.contenuto.bevande" class="font-medium">
					Bevande
				</p>
				<div
					v-for="(bevanda, index) of ordine.contenuto.bevande"
					:key="index"
					class="flex justify-between"
				>
					<p>{{ capitalize(bevanda.nome) }} {{ bevanda.litri }}l</p>
					<p>€ {{ bevanda.prezzo }}</p>
				</div>
			</div>
		</div>
		<router-link
			to="/utente/ordina"
			class="fixed bottom-4 right-4 cursor-pointer"
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="42"
				height="42"
				viewBox="0 0 73 73"
			>
				<g
					id="Group_2"
					data-name="Group 2"
					transform="translate(-1783 -963)"
				>
					<circle
						id="Ellipse_2"
						data-name="Ellipse 2"
						cx="36.5"
						cy="36.5"
						r="36.5"
						transform="translate(1783 963)"
						class="fill-red"
					/>
					<path
						id="Union_1"
						data-name="Union 1"
						d="M12.5,28.5v-9h-9a3.5,3.5,0,0,1,0-7h9v-9a3.5,3.5,0,0,1,7,0v9h9a3.5,3.5,0,0,1,0,7h-9v9a3.5,3.5,0,0,1-7,0Z"
						transform="translate(1803 983)"
						class="fill-white"
					/>
				</g>
			</svg>
		</router-link>
	</div>
</template>

<script>
import { useCache } from "@/stores/cache";
import { useUserStore } from "@/stores/userStore";
import { capitalize, formatTime } from "@/scripts/utils.js";

export default {
	name: "Home",
	data() {
		return {
			negozi: {},
			userStore: useUserStore(),
		};
	},
	methods: {
		capitalize,
		formatTime,
		logOut() {
			localStorage.clear();
			this.$router.push({ name: "authform" });
		},
	},
	async created() {
		let cache = useCache();
		let negoziData = await cache.fetch("/utenti/lista-negozi");
		for (let negozio of negoziData) {
			this.negozi[negozio.id] = negozio;
		}
	},
};
</script>