<template>
	<p v-if="!allowedHours.size || !allowedIntervals.length">
		Il negozio è chiuso
	</p>
	<div v-else class="w-fit p-4">
		<div class="flex justify-center text-1x">
			<p
				class="p-1 cursor-pointer"
				:class="{
					'text-red': mode == 'hours',
					'text-grey-light': !isValid,
				}"
				@click="mode = 'hours'"
			>
				{{ hoursFormatted }}
			</p>
			<p>:</p>
			<p
				class="p-1 cursor-pointer"
				:class="{
					'text-red': mode == 'minutes',
					'text-grey-light': !isValid,
				}"
				@click="mode = 'minutes'"
			>
				{{ minutesFormatted }}
			</p>
		</div>
		<div
			class="w-fit cursor-pointer"
			:class="{ 'cursor-grabbing': dragging }"
			:style="{ padding: padding + 'px' }"
			@mousedown="handleMouseDown"
			@mouseup="handleMouseUp"
			@mousemove="handleMouseMove"
			@touchstart="handleTouchStart"
			@touchmove="handleTouchMove"
			@touchend="handleTouchEnd"
			@touchcancel="handleTouchEnd"
		>
			<div
				class="bg-white rounded-full relative shadow-lg"
				:style="{ width: clockSize + 'px', height: clockSize + 'px' }"
			>
				<div class="absolute top-1/2 left-1/2 pointer-events-none z-10">
					<span
						v-for="(number, index) of numberList"
						:key="number"
						class="absolute text-center"
						:class="{
							'text-white': this.picked == number,
							'text-grey-light': !allowed(number),
						}"
						:style="{
							transform: translate(index, clockRadius),
							width: numberSize + 'px',
							height: numberSize + 'px',
						}"
						>{{ number }}</span
					>
				</div>
				<svg
					:width="clockSize"
					:height="clockSize"
					:viewBox="`0 0 ${clockSize} ${clockSize}`"
					:style="{ transform: `rotate(${clockHandAngle}deg)` }"
					xmlns="http://www.w3.org/2000/svg"
					class="stroke-red fill-red pointer-events-none"
				>
					<line
						:x1="clockRadius"
						:y1="clockRadius"
						:x2="clockRadius"
						y2="19"
						stroke-width="1"
					></line>
					<circle
						:cx="clockRadius"
						:cy="clockRadius"
						r="1.5"
					></circle>
					<circle :cx="clockRadius" cy="19" r="17"></circle>
				</svg>
			</div>
		</div>
		<div class="flex p-4 justify-around">
			<button
				class="p-2 rounded-full m-2 shadow-md disabled:bg-grey-light"
				:class="{ 'bg-red text-white': meridiem == 'am' }"
				@click="meridiem = 'am'"
				:disabled="allowedHoursAm.size == 0"
			>
				AM
			</button>
			<button
				class="p-2 rounded-full m-2 shadow-md disabled:bg-grey-light"
				:class="{ 'bg-red text-white': meridiem == 'pm' }"
				@click="meridiem = 'pm'"
				:disabled="allowedHoursPm.size == 0"
			>
				PM
			</button>
		</div>
	</div>
</template>

<script>
import {
	MINUTES_INTERVALS,
	HOURS_LIST,
	MINUTES_LIST,
	NUMBER_SIZE,
	translate,
	calculatePoint,
	calculateTime,
	calculateAllowed,
} from "../scripts/timepicker";

export default {
	name: "TimePicker",
	props: {
		clockRadius: {
			type: Number,
			default: 110,
		},
		minutesInterval: {
			type: Number,
			default: 5,
		},
		allowedIntervals: {
			type: Array,
			default: [],
		},
	},
	emits: ["time-change"],
	data() {
		return {
			mode: "hours", //hours, minutes
			meridiem: "am", //am, pm
			hours: 12,
			minutes: 0,
			numberSize: NUMBER_SIZE,
			padding: 18,
			dragging: false,
			clockHandAngle: 0,
			minutesIncrement: 3, // 15 / 5 == 3
			allowedHours: new Set(),
			allowedMinutes: new Set(),
			isValid: true,
		};
	},
	computed: {
		clockSize() {
			return this.clockRadius * 2;
		},
		numberList() {
			return this.mode == "hours" ? HOURS_LIST : MINUTES_LIST;
		},
		hoursFormatted() {
			return this.meridiem == "am" ? this.hours : (12 + this.hours) % 24;
		},
		minutesFormatted() {
			return ("0" + ((this.minutes * 5) % 60)).slice(-2);
		},
		completeTime() {
			let currentTime = new Date();
			currentTime.setHours(this.hoursFormatted);
			if (this.hoursFormatted == 0)
				currentTime.setDate(currentTime.getDate() + 1);
			currentTime.setMinutes((this.minutes * 5) % 60, 0, 0);
			return currentTime.toISOString();
		},
		picked() {
			return this.mode == "hours" ? this.hours : (this.minutes * 5) % 60;
		},
		allowedHoursAm() {
			return new Set(
				[...this.allowedHours].filter((x) => x < 13 && x != 0)
			);
		},
		allowedHoursPm() {
			return new Set(
				[...this.allowedHours].filter((x) => x > 12 || x == 0)
			);
		},
	},
	watch: {
		mode(newValue, oldValue) {
			if (this.mode == "hours") {
				this.clockHandAngle = (this.hours % 12) * (360 / 12);
			} else {
				this.clockHandAngle = (this.minutes % 12) * (360 / 12);
			}
		},
		meridiem(newValue, oldValue) {
			this.mode = "hours";
			let time = 0;
			if (this.meridiem == "am") {
				time = [...this.allowedHoursAm][0];
			} else {
				time = [...this.allowedHoursPm][0];
				time %= 12;
			}
			this.clockHandAngle = time * (360 / 12);
			this.hours = time;
		},
		completeTime(newValue, oldValue) {
			this.$emit("time-change", this.completeTime);
		},
		allowedIntervals(newValue, oldValue) {
			this.updateClock();
		},
		allowedHours(newValue, oldValue) {
			this.checkValidity();
		},
		allowedMinutes(newValue, oldValue) {
			this.checkValidity();
		},
	},
	methods: {
		translate,
		mouseChange(offsetX, offsetY) {
			let angle = calculatePoint(offsetX, offsetY, this.clockRadius);
			let time = calculateTime(angle, this.mode == "hours" ? 1 : 3);
			if (this.mode == "hours") {
				if (
					!this.allowedHours.has(
						this.meridiem == "am" ? time : (time + 12) % 24
					)
				)
					return;
				this.hours = time;
				this.clockHandAngle = (time % 12) * (360 / 12);
				this.updateAllowed("minutes");
				time = 0;
				if (this.allowedMinutes.size) {
					time = Math.floor([...this.allowedMinutes][0] / 5);
				}
				this.minutes = time;
			} else {
				let minutes = (time * 5) % 60;
				if (!this.allowedMinutes.has(minutes)) return;
				this.minutes = time;
				this.clockHandAngle = (time % 12) * (360 / 12);
			}
			this.checkValidity();
		},
		handleMouseDown(e) {
			this.dragging = true;
			this.mouseChange(e.offsetX, e.offsetY);
		},
		handleMouseUp(e) {
			this.dragging = false;
			this.mode = "minutes";
		},
		handleMouseMove(e) {
			if (!this.dragging) return;
			this.mouseChange(e.offsetX, e.offsetY);
		},
		handleTouchStart(e) {
			e.preventDefault();
			this.dragging = true;
		},
		handleTouchMove(e) {
			e.preventDefault();
			if (!this.dragging) return;
			const rect = e.target.getBoundingClientRect();
			const offsetX =
				e.touches[0].clientX - window.pageXOffset - rect.left;
			const offsetY =
				e.touches[0].clientY - window.pageYOffset - rect.top;
			this.mouseChange(offsetX, offsetY);
		},
		handleTouchEnd(e) {
			this.dragging = false;
			this.mode = "minutes";
		},
		allowed(number) {
			if (this.mode == "hours") {
				if (this.meridiem == "pm") number = (number + 12) % 24;
				return this.allowedHours.has(number);
			} else {
				return this.allowedMinutes.has(number);
			}
		},
		updateAllowed(mode) {
			if (mode == "hours") {
				this.allowedHours = new Set(
					calculateAllowed(
						mode,
						this.minutesIncrement,
						this.hoursFormatted,
						this.allowedIntervals
					)
				);
			} else {
				this.allowedMinutes = new Set(
					calculateAllowed(
						mode,
						this.minutesIncrement,
						this.hoursFormatted,
						this.allowedIntervals
					)
				);
			}
		},
		updateClock() {
			this.updateAllowed("hours");
			this.updateAllowed("minutes");
		},
		checkValidity() {
			if (this.mode == "hours") {
				this.valid = this.allowedHours.has(this.hoursFormatted);
			} else {
				this.valid = this.allowedMinutes.has(+this.minutesFormatted);
			}
		},
	},
	created() {
		this.updateClock();
		setInterval(this.updateClock, 1000 * 30);
		if (!this.allowedHours.size) return;
		let time = 0;
		if (this.allowedHoursAm.size) {
			time = [...this.allowedHoursAm][0];
		} else {
			this.meridiem = "pm";
			time = [...this.allowedHoursPm][0];
			time %= 12;
		}
		this.clockHandAngle = time * (360 / 12);
		this.hours = time;
	},
};
</script>