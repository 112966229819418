const MINUTES_INTERVALS = [5, 10, 15, 20, 30, 60];

const MINUTES_LIST = Array.from(Array(12), (_, i) => i * 5);
const HOURS_24_LIST = Array.from(Array(24), (_, i) => (i + 1) % 24);
const HOURS_LIST = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const NUMBER_SIZE = 24;

const ANGLE_INCREMENT = 360 / MINUTES_LIST.length;
const MINUTES_DELAY = 15;

const { cos, sin, atan2 } = Math;
const pi = Math.PI;

// deg : 180 = rad : pi

function rad(deg) {
    return deg * pi / 180;
}

function deg(rad) {
    return 180 * rad / pi;
}

const range = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => min + i);

function calculateAllowed(mode, minutesIncrement = 1, pickedHours = -1, allowedIntervals = []) {
    let currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + MINUTES_DELAY);
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    let allowedHours, allowedMinutes;
    if (allowedIntervals.length) {
        allowedHours = [...new Set(allowedIntervals.map(x => range(x.start[0], x.end[0])).flat())];
        let match = allowedIntervals.find(x => x.start[0] == pickedHours || x.end[0] == pickedHours);
        allowedMinutes = match ? MINUTES_LIST.filter(x =>
            match.start[0] == pickedHours && match.end[0] == pickedHours ?
                match.start[1] <= x && x <= match.end[1] :
                (match.start[0] == pickedHours ? x >= match.start[1] : x <= match.end[1])) : [...MINUTES_LIST];
    } else {
        [allowedHours, allowedMinutes] = [[...HOURS_24_LIST], [...MINUTES_LIST]]
    }
    let minutesFiltered = allowedMinutes.filter(x => x % (minutesIncrement * 5) == 0 && (pickedHours == hours ? x > minutes : true));
    let currentMinutesFiltered = allowedMinutes.filter(x => x % (minutesIncrement * 5) == 0 && x > minutes);
    let hoursFiltered = allowedHours.filter(x => (x >= hours || x == 0) && (x == hours ? currentMinutesFiltered.length : true));
    if (mode == 'hours') {
        return hoursFiltered;
    } else {
        return minutesFiltered;
    }
}


function translate(i, r) {
    let deg = - 90 + i * ANGLE_INCREMENT;
    let x = cos(rad(deg)) * (r - NUMBER_SIZE / 2 - 6) - NUMBER_SIZE / 2;
    let y = sin(rad(deg)) * (r - NUMBER_SIZE / 2 - 6) - NUMBER_SIZE / 2;
    return `translate(${x}px, ${y}px)`;
}

function calculatePoint(offsetX, offsetY, r) {
    let x = offsetX - r;
    let y = -offsetY + r;
    const a = atan2(y, x);
    let d = 90 - deg(a);
    if (d < 0) d += 360;
    return d;
}

function calculateTime(angle, minutesIncrement = 1) {
    let value = Math.round((angle / 360 * 12) / minutesIncrement) * minutesIncrement;
    if (value == 0) value = 12;
    return value;
}

export { MINUTES_INTERVALS, MINUTES_LIST, HOURS_LIST, NUMBER_SIZE, translate, calculatePoint, calculateTime, calculateAllowed }

