import { defineStore } from 'pinia';

export const useNegozioStore = defineStore('negozio', {
    state: () => {
        return {
            authToken: '',
            nome: '',
        };
    },
    persist: true
});